import { render, staticRenderFns } from "./CreateEditClient.vue?vue&type=template&id=7501d3ba&scoped=true&"
import script from "./CreateEditClient.vue?vue&type=script&lang=js&"
export * from "./CreateEditClient.vue?vue&type=script&lang=js&"
import style0 from "./CreateEditClient.vue?vue&type=style&index=0&id=7501d3ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7501d3ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VCard,VCheckbox,VCol,VCombobox,VIcon,VListItemContent,VRow,VSelect,VTextField})
