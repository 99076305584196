import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // form statuess
    inViewMode: false,
    inAddMode: false,
    inEditMode: false,
    inDeleteMode: false,
    inDetailsMode: false,
    inJustCancelMode: false,
    inUpdateMode: false,
    inBillingMode: false,
    inSchedulerMode: false,
    inSchedulerEditMode: false,
    inSchedulerListingMode: false,
    inReceptionCreateMode: false,
    inStockListingMode: false,
    inTyreDepotMode: false,
    colorCodes: {
      success: "#d1e7dd",
      danger: "#f9d7da",
      warning: "#fff4cd",
      primary: "#cfe2ff",
      secondary: "#e2e3e5",
      info: "cff5fd",
      light: "#f8fafa",
      dark: "#202528",
    },

    // show side nav drawer
    showNavDrawer: true,

    // add button route
    addButtonRoute: "",

    // global search
    search: "",

    // Access_Token
    AccessToken: localStorage.getItem("accessToken") || "",

    // Role Details
    role: null,

    // Duplicate Role
    DuplicateRole: localStorage.getItem("DuplicateRole")
      ? JSON.parse(localStorage.getItem("DuplicateRole"))
      : null,

    // CompanyID
    companyId: localStorage.getItem("companyID") || "",
    loginUser: localStorage.getItem("loginUser") || "",
    languageId: localStorage.getItem("languageId") || "",
    currencyId: localStorage.getItem("currencyId") || "",
    isLoading: false,

    // billing module
    billType: null,
    billTypes: [],
    billCancelVisible: false,
    showPrintReportInPrintDropDown: false,
    isEditingAllowed: false,
    makePurchaseOrder: false,
    makeBillVisible: false,
    makeCreditNote: false,
    showNewButton: false,
    isBillSelected: false,
    selectedBillData: null,
    showSaveButton: false,
    validation: false,
    editId: null,

    // Billing and Scheduler Module
    showDepotButtons: 0,
    schedulerDate: null,

    // appointment
    appointment: null,

    // Global Variables
    familyArticles: [],
    approvals: [],
    tyreTypes: [],
    projectStatuses: [],
    wheelTypes: [],
    tyreSeasons: [],
    resourceTypes: [],
    statuses: [],
    familyClients: [],
    refreshList: false,
    storedworkresources: [],
    workresources: [],

    attachmentCounts: 0,
    vehicleId: null,

    alerts: [],

    cachedData: {
      brands: {},
    },

    addRentalVehicleFormActive: false,
    rentalId: null,
    vehicles: [],
  },
  mutations: {
    /**
     * Put form in edit or view mode
     * @param state
     * @param mode (128|64|32|16|8|4|2|1) in binary
     */
    setFormMode(state, mode) {
      const binValue = mode.padStart(9, "0");
      // DEAV (Delete, Edit, Add, View) = (128|64|32|16|8|4|2|1)

      function checkTruePositionsExcept(binValue, exceptPositions) {
        for (var i = 0; i < binValue.length; i++) {
          let positionNotToCheck;
          for (var j = 0; j < exceptPositions.length; j++) {
            if (i == exceptPositions[j]) {
              positionNotToCheck = i;
            }
          }

          if (binValue.charAt(i) == "1" && positionNotToCheck == undefined) {
            return true;
          }
        }
        return false;
      }

      state.inViewMode =
        !!parseInt(binValue[8]) &&
        checkTruePositionsExcept(binValue, [8]) == false;
      state.inAddMode =
        !!parseInt(binValue[7]) &&
        checkTruePositionsExcept(binValue, [7]) == false;
      state.inUpdateMode =
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        checkTruePositionsExcept(binValue, [8, 7]) == false;
      state.inEditMode =
        !!parseInt(binValue[6]) &&
        checkTruePositionsExcept(binValue, [6]) == false;
      state.inDeleteMode =
        !!parseInt(binValue[5]) &&
        checkTruePositionsExcept(binValue, [5]) == false;
      state.inDetailsMode =
        !!parseInt(binValue[4]) &&
        checkTruePositionsExcept(binValue, [4]) == false;
      state.inJustCancelMode =
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        checkTruePositionsExcept(binValue, [8, 7, 6]) == false;

      state.inBillingMode =
        !!parseInt(binValue[3]) &&
        checkTruePositionsExcept(binValue, [3]) == false;
      state.inSchedulerMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[4]) &&
        checkTruePositionsExcept(binValue, [3, 4]) == false;
      state.inSchedulerEditMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[5]) &&
        checkTruePositionsExcept(binValue, [3, 4, 5]) == false;
      state.inSchedulerListingMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[5]) &&
        !!parseInt(binValue[6]) &&
        checkTruePositionsExcept(binValue, [3, 4, 5, 6]) == false;
      state.inReceptionCreateMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[8]) &&
        checkTruePositionsExcept(binValue, [3, 8]) == false;
      state.inStockListingMode =
        !!parseInt(binValue[0]) &&
        !!parseInt(binValue[1]) &&
        checkTruePositionsExcept(binValue, [0, 1]) == false;
      state.inTyreDepotMode =
        !!parseInt(binValue[0]) &&
        !!parseInt(binValue[1]) &&
        !!parseInt(binValue[2]) &&
        checkTruePositionsExcept(binValue, [0, 1, 2]) == false;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    invertNavDrawerVisibility(state) {
      state.showNavDrawer = !state.showNavDrawer;
    },
    setAddButtonRoute(state, route) {
      state.addButtonRoute = route;
    },
    setGlobalSearch(state, search) {
      state.search = search;
    },
    setBillType(state, billType) {
      state.billType = billType;
    },
    setBillTypes(state, billTypes) {
      state.billTypes = billTypes;
    },
    setBillCancelVisible(state, isVisible) {
      state.billCancelVisible = isVisible;
    },
    setShowPrintReportInPrintDropDown(state, isVisible) {
      state.showPrintReportInPrintDropDown = isVisible;
    },
    setMakePurchaseOrderVisible(state, isVisible) {
      state.makePurchaseOrder = isVisible;
    },
    setMakeBillVisible(state, isVisible) {
      state.makeBillVisible = isVisible;
    },
    setMakeCreditNoteVisible(state, isVisible) {
      state.makeCreditNote = isVisible;
    },
    setShowNewButton(state, isVisible) {
      state.showNewButton = isVisible;
    },
    setShowSaveButton(state, isVisible) {
      state.showSaveButton = isVisible;
    },
    setIsBillSelected(state, isSelected) {
      state.isBillSelected = isSelected;
    },
    setSelectedBillData(state, data) {
      if (data) {
        state.selectedBillData = data;
      } else {
        state.selectedBillData = null;
      }
    },
    setValidation(state, onOrOff) {
      state.validation = onOrOff;
    },
    seteditId(state, billId = null) {
      state.editId = billId;
    },
    setVehicleId(state, vehicleId = null) {
      state.vehicleId = vehicleId;
    },
    setShowDepotButtons(state, visibleStatus) {
      state.showDepotButtons = visibleStatus;
    },
    setAppointmentRedirectData(state, appointment) {
      state.appointment = appointment;
    },
    setEditingAllowed(state, allowed) {
      state.isEditingAllowed = allowed;
    },
    setSchedulerDate(state, date) {
      state.schedulerDate = date;
    },

    // Set Global variable
    setFamilyArticles(state, familyArticles) {
      state.familyArticles = familyArticles;
    },
    setApprovals(state, approvals) {
      state.approvals = approvals;
    },
    setClientProjectsStatus(state, projectStatuses){
        state.projectStatuses = projectStatuses;
      },
    setTyreTypes(state, tyreTypes) {
      state.tyreTypes = tyreTypes;
    },
    setWheelTypes(state, wheelTypes) {
      state.wheelTypes = wheelTypes;
    },
    setTyreSeasons(state, tyreSeasons) {
      state.tyreSeasons = tyreSeasons;
    },
    setStatuses(state, statuses) {
      state.statuses = statuses;
    },
    setFamilyClients(state, familyClients) {
      state.familyClients = familyClients;
    },
    setResourceTypes(state, resourceTypes) {
      state.resourceTypes = resourceTypes;
    },
    setRefreshList(state, refreshList) {
      state.refreshList = refreshList;
    },
    setAttachmentCounts(state, attachmentCounts) {
      state.attachmentCounts = attachmentCounts;
    },
    setRentalVehicleForm(state, value) {
      // console.log('kjgjkgjk', value)
      state.addRentalVehicleFormActive = value;
    },
    setRentalId(state, value) {
      state.rentalId = value;
    },
    setVehicles(state, value) {
      state.vehicles = value;
    },
  },
  actions: {
    async fetchAddressData(state, search_query = null) {
      if (search_query && search_query != null) {
        const promise = await axios.get(
          "https://api.geoapify.com/v1/geocode/search?apiKey=" +
            process.env.VUE_APP_GEOAPIFY_API_KEY +
            "&text=" +
            search_query.toString() +
            "&format=json"
        );

        Promise.all([promise]).then(function(values) {
          return values;
        });
      }
    },
  },
  modules: {},
});
