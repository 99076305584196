<template>
    <v-row>
        <v-col class="pt-0 pb-0">
            <v-combobox
                :autocomplete="autocompleteValueLocal"
                v-model="name"
                :items="clients_projects"
                :item-text="'name'"
                :item-value="'name'"
                :prepend-icon="'mdi-plus'"
                @click:prepend="clearClientProject"
                class="to-upper"
                :label="$t('billings.project_name')"
                v-on:keydown="handleInput($event, 'alphanumdash', 50)"
                :error-messages="
                    $store.state.validation
                        ? returnErrorMessage([
                              !$v.name.maxLength,
                              this.$t(
                                  'billings.text_messages.project_name_max_length'
                              ),
                          ])
                        : []
                "
                v-on:keydown.enter.prevent=""
                :outlined="is_boxes"
                :dense="is_boxes"
            >
            </v-combobox>
        </v-col>
        <v-col class="pt-0 pb-0">
            <v-textarea
                :autocomplete="autocompleteValueLocal"
                v-model="note"
                class="to-upper"
                rows="1"
                :label="$t('billings.project_note')"
                v-on:keydown="handleInput($event, 'everything', 2000)"
                v-on:keydown.enter.prevent=""
                :outlined="is_boxes"
                :dense="is_boxes"
            >
            </v-textarea>
        </v-col>
        <v-col class="pt-0 pb-0">
            <v-combobox
                :autocomplete="autocompleteValueLocal"
                v-model="project_status"
                :items="projectStatuses"
                item-text="text"
                item-value="id"
                class="to-upper"
                return-object
                :label="$t('billings.project_status')"
                v-on:keydown="handleInput($event, 'alphanumdash', 100)"
                v-on:keydown.enter.prevent=""
                :outlined="is_boxes"
                :dense="is_boxes"
            >
            </v-combobox>
        </v-col>
        <v-btn
            :disabled="!project || (project && typeof project != 'object')"
            id="attachments"
            class="ma-2 text-white-color"
            color="success"
            @click="showAttachmentDialog()"
        >
            {{ $t("attachments") }} ({{ $store.state.attachmentCounts }})
            <v-icon dark right> mdi-paperclip </v-icon>
        </v-btn>
        <attachment-dialog
            :showDialog.sync="attachmentDialog"
            :data="project && typeof project == 'object' ? project.id : null"
            :resource="'projects'"
        ></attachment-dialog>
    </v-row>
</template>

<script>
import AttachmentDialog from "@/components/AttachmentDialog";
import { validationMixin } from "vuelidate";
import { API_BASE_URL, FAMILY_ARTICLE_CARS } from "@/config";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import axios from "axios";
import { type } from "os";

export default {
    name: "CreateExitProject",
    components: {
        "attachment-dialog": AttachmentDialog,
    },
    mixins: [validationMixin],
    validations: {
        name: {
            maxLength: maxLength(200),
        },
    },
    props: {
        project: [String, Object],
        project_client: [String, Object],
        project_name: [String, Object],
        project_note: [String, Object],
        project_status_id: [Number],
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            attachmentDialog: false,
            autocompleteValueLocal: "off",
            client_details: [],
            note: this.project_note,
            clients_projects: [],
            project_status: null,
            name: this.project_name,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
        };
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
    },
    activated() {
        this.setDefaultProjectStatus();
    },
    methods: {
        setDefaultProjectStatus() {
            if (this.projectStatuses.length > 0) {
                this.projectStatuses.forEach((element) => {
                    if (element.key == "in_preparation") {
                        this.project_status = element;
                        this.$emit("update:project_status_id", element.id);
                    }
                });
            }
        },
        showAttachmentDialog() {
            this.attachmentDialog = true;
        },
        clearClientProject() {
            this.$emit("update:project", null);
        },
        getProjectClients(id) {
            this.clients_projects = [];
            axios
                .get(API_BASE_URL + "/client_projects/" + id, {
                    headers: this.header,
                })
                .then(({ data }) => {
                    console.log("client projects", data, data.data);
                    this.clients_projects = data;
                })
                .catch(function(error) {
                    this.clients_projects = [];
                    console.log("an error occured " + error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
    },
    computed: {
        isEditingAllowed() {
            return this.$store.state.isEditingAllowed;
        },
    },
    watch: {
        project_name(val){
            this.name = val;
        },
        project_note(val){
            this.note = val;
        },
        project(val) {
            console.log("reset parent project");
            if (val) {
                this.name = val.name;
                this.location = val.location;
                this.locations = [val.location];
                this.note = val.note;
                this.projectStatuses.forEach((element) => {
                    if (element.id == this.project.status_id) {
                        console.log(
                            "project element status",
                            this.project.status_id
                        );
                        this.project_status = element;
                    }
                });
                // if(val && val.client_id){

                // }
            } else {
                console.log("reset parent project");
                this.name = null;
                this.location = null;
                this.locations = [];
                this.note = null;
                this.setDefaultProjectStatus();
            }
        },
        project_client(val) {
            this.clients_projects = [];
            if (val && val.id) {
                this.getProjectClients(val.id);
            }
        },
        projectStatuses() {
            this.setDefaultProjectStatus();
        },
        name(val) {
            if (val && typeof val == "object") {
                this.$emit("update:project", val);
                this.$emit("update:project_name", val.name);
            } else {
                this.$emit("update:project_name", val);
            }
        },
        note(val) {
            this.$emit("update:project_note", val);
        },
        project_status: {
            handler: function(val) {
                if (val) {
                    if (this.project_name == null) {
                        this.setDefaultProjectStatus();
                    }
                    this.$emit("update:project_status_id", val.id);
                }
            },
            immediate: true,
        },
        project_status_id(val) {
            if (val) {
                console.log("project element status changed status id---", val);
                this.projectStatuses.forEach((element) => {
                    if (element.id == val) {
                        console.log(
                            "project element status changed status id",
                            val,
                            element
                        );
                        this.project_status = element;
                    }
                });
            }
            else{
                this.setDefaultProjectStatus();
            }
        },
    },
};
</script>

<style scoped></style>
