<template>
    <v-card elevation="0" style="width:100%">
        <v-row v-if="checkTag('clients')">
            <v-col class="" cols="12" sm="2">
                <v-row>
                    <v-col class="pt-0 pb-0" cols="12" sm="6">
                        <v-autocomplete
                            class="to-upper"
                            :autocomplete="autocompleteValueLocal"
                            :disabled="isEditingAllowed"
                            v-model="familyClient"
                            :items="familyClients"
                            :label="$t('pricing_rule.family_client')"
                            item-value="id"
                            item-text="name"
                            menu-props="auto"
                            ref="family_client"
                            v-on:keydown.enter.prevent=""
                            @change="$emit('update:comp_familyClient', familyClient);"
                            :error-messages="$store.state.validation && !$v.familyClient.required ? [this.$t('billings.text_messages.family_client_required')] : [] "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="6">
                        <v-select
                            class="to-upper"
                            :disabled="isEditingAllowed"
                            v-model="entityType"
                            :items="entityTypeSelectItems"
                            :label="$t('billings.entity_type')"
                            item-text="text"
                            item-value="id"
                            return-object
                            v-on:keydown.enter.prevent=""
                            @change="$emit('update:comp_entityType', entityType);"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col style="display:flex" class="pt-0 pb-0" cols="12" :sm="isCompanyEntitySelected ? 4 : 3">
                <v-icon style="display:inline-flex" readonly v-if="isEditingAllowedBeforeBill" color="primary" @click.native="editClient">mdi-pencil</v-icon>
                <v-combobox
                    @click.native=" isEditingAllowed ? editClient :''"
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    ref="clientName"
                    :disabled="isEditingAllowed"
                    v-model="company_client_name"
                    :items="company_client_names"
                    :error-messages="$store.state.validation && !$v.company_client_name.required ? [this.$t('billings.text_messages.client_or_company_name_required')] : [] "
                    item-value="id"
                    item-text="name"
                    :label="isCompanyEntitySelected ? $t('billings.comp_name') : $t('billings.family_name')"
                    required
                    @keyup="$emit('update:comp_company_client_name', company_client_name);$emit('update:comp_search_client', search_client);"
                    @change="$emit('update:comp_company_client_name', company_client_name);"
                    append-icon="mdi-close"
                    @click:append="onResetPageClient()"
                    v-on:keydown="handleInput($event,'alphanumeric',50);"
                    :search-input.sync="search_client"
                    no-filter
                    v-on:keydown.enter.prevent=""
                    return-object
                    :outlined="is_boxes"
                    :dense="is_boxes"
                >
                    <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                            <p class='client_name'>{{item.name}} {{item.first_name}}</p>
                            <p class='client_detail' v-if="item.representative_company_id"> Company </p>
                            <p class='client_detail' v-else> Private </p>
                            <p class='client_detail'>{{item.email}}</p>
                        </v-list-item-content>
                    </template>
                </v-combobox>
            </v-col>
            <v-col v-if="!isCompanyEntitySelected" class="pt-0 pb-0" cols="12" :sm="2">
                <v-text-field
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    ref="first_name"
                    :disabled="isEditingAllowed"
                    v-model="company_client_first_name"
                    :label="$t('billings.client_first_name')"
                    required
                    @keyup="$emit('update:comp_company_client_first_name', company_client_first_name);"
                    @change="$emit('update:comp_company_client_first_name', company_client_first_name);"
                    v-on:keydown="handleInput($event,'alphanumeric',50);"
                    no-filter
                    v-on:keydown.enter.prevent=""
                >
                </v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12" sm="1" v-if="isCompanyEntitySelected">
                <v-select
                    class="to-upper"
                    :disabled="isEditingAllowed"
                    v-model="denomination"
                    :items="denominations"
                    item-value="id"
                    item-text="text"
                    :label="$t('billings.denomination')"
                    v-on:keydown.enter.prevent=""
                    @change="$emit('update:comp_denomination', denomination);"
                    :error-messages="$store.state.validation && !$v.denomination.required ? [this.$t('billings.text_messages.denomination_required')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-select>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12" sm="1" v-if="isCompanyEntitySelected">
                <v-text-field
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    :disabled="isEditingAllowed"
                    v-model="tva"
                    :label="$t('billings.tva_number')"
                    required
                    v-on:keydown.enter.prevent=""
                    @keyup="$emit('update:comp_tva', tva);"
                    v-on:keydown="handleInput($event,'justalphanumericwithoutspace',12);"
                    :error-messages="$store.state.validation && !$v.tva.required ? [this.$t('billings.text_messages.tva_required')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-autocomplete
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    flat
                    :items="languages"
                    v-model="language"
                    :label="$t('billings.languages')"
                    @change="$emit('update:comp_company_client_language', language);"
                    item-value="id"
                    item-text="text"
                    v-on:keydown.enter.prevent=""
                    :error-messages="$store.state.validation && !$v.country.required ? [this.$t('billings.text_messages.country_required')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                ></v-autocomplete>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-text-field 
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    
                    v-model="company_client_email" 
                    :label="$t('billings.email')"
                    v-on:keydown.enter.prevent=""
                    :error-messages="$store.state.validation && !$v.company_client_email.email ? [this.$t('billings.text_messages.invalid_email')] : [] "
                    @keyup="$emit('update:comp_company_client_email', company_client_email);"
                    v-on:keydown="handleInput($event,'email',50);"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-text-field 
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    
                    v-model="company_client_gsm" 
                    :label="$t('billings.gsm')"
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="handleInput($event,'phonenumber',45);"
                    @keyup="$emit('update:comp_company_client_gsm', company_client_gsm);"
                    :error-messages="$store.state.validation && !$v.company_client_gsm.maxLength ? [this.$t('billings.text_messages.invalid_GSM_length')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-text-field 
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    
                    v-model="company_client_phone" 
                    :label="$t('billings.phone')"
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="handleInput($event,'phonenumber',45);"
                    @keyup="$emit('update:comp_company_client_phone', company_client_phone);"
                    :error-messages="$store.state.validation && !$v.company_client_phone.maxLength ? [this.$t('billings.text_messages.invalid_phone_number')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row class="group_label" v-if="checkTag('client_address') || checkTag('client_billing_address')">
            <v-col class="pb-2">
                <span style="color: #2196F3"> Billing Address </span>
            </v-col>
        </v-row>

        <v-row v-if="checkTag('client_address') || checkTag('client_billing_address')">
            <v-col class="pt-0 pb-0" cols="12" :sm="isCompanyEntitySelected ? 4 : 5">
                <v-combobox
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    @input.native="$emit('update:comp_address', search_address);fetchAddressSuggListDebounced()"
                    v-model="address"
                    :items="addresses"
                    item-value="id"
                    item-text="street"
                    :label="$t('billings.address')"
                    :search-input.sync="search_address"
                    :loading="isLoadingSearchAddress"
                    v-on:keydown.enter.prevent=""
                    @change="$emit('update:comp_address', address);"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-combobox>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-text-field 
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    
                    v-model="zipcode" 
                    :label="$t('billings.zip_code')"
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="handleInput($event,'alphanumeric',10);"
                    @change="$emit('update:comp_zipcode', zipcode);"
                    :error-messages="$store.state.validation && !$v.zipcode.required ? [this.$t('billings.text_messages.zipcode_required')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-combobox
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    @input.native="$emit('update:comp_city', city);fetchCitySuggListDebounced()"
                    v-model="city" 
                    :items="cities"
                    item-value="id"
                    item-text="name"
                    :label="$t('billings.city')"
                    :search-input.sync="search_city"
                    :loading="isLoadingSearchCity"
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="handleInput($event,'alpha',50);"
                    @change="$emit('update:comp_city', city);"
                    :error-messages="$store.state.validation && !$v.city.required ? [this.$t('billings.text_messages.city_required')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-combobox>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-combobox
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    @input.native="$emit('update:comp_county', search_county);fetchCountySuggListDebounced()"
                    v-model="county" 
                    :items="counties"
                    item-value="county"
                    item-text="county"
                    :label="$t('billings.county')"
                    :search-input.sync="search_county"
                    :loading="isLoadingSearchCounty"
                    v-on:keydown="handleInput($event,'alpha',50);"
                    v-on:keydown.enter.prevent=""
                    @change="$emit('update:comp_county', county);"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-combobox>
            </v-col>
            <v-col class="pt-0 pb-0">
                <v-autocomplete
                    class="to-upper"
                    :autocomplete="autocompleteValueLocal"
                    
                    flat
                    :items="countries"
                    v-model="country"
                    :label="$t('billings.country')"
                    @change="$emit('update:comp_country', country);"
                    item-value="id"
                    item-text="key"
                    v-on:keydown.enter.prevent=""
                    :loading="isLoadingSearchCountry"
                    :error-messages="$store.state.validation && !$v.country.required ? [this.$t('billings.text_messages.country_required')] : [] "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                ></v-autocomplete>
            </v-col>
        </v-row>

        <template v-if=" ( checkTag('client_address') || checkTag('client_shipping_address') ) ? comp_is_shipping_visible : false">
            <v-row class="group_label">
                <v-col class="pb-0 d-flex col-auto mr-auto">
                    <span style="color: #2196F3"> Shipping Address </span>
                </v-col>
                <v-col class="pb-0 pt-2 d-flex" v-on:keydown.enter.prevent="">
                    <v-checkbox
                        :disabled="isEditingAllowed"
                        class="ma-0 to-upper"
                        v-model="same_as"
                        @change="$emit('update:comp_same_as', same_as);"
                        :label="$t('billings.same_as_billing_address')"
                    ></v-checkbox>
                </v-col>
            </v-row>


            <v-row>
                <v-col class="pt-0 pb-0" cols="12" :sm="isCompanyEntitySelected ? 4 : 5">
                    <v-combobox
                        class="to-upper"
                        :disabled="isEditingAllowed || editShippingAddress"
                        v-model="shipping_address"
                        @input.native="$emit('update:comp_shipping_address', search_shipping_address);fetchShippingAddressSuggListDebounced()"
                        :items="shipping_addresses"
                        item-value="id"
                        item-text="street"
                        :label="$t('billings.address')"
                        :search-input.sync="search_shipping_address"
                        :loading="isLoadingSearchShippingAddress"
                        v-on:keydown.enter.prevent=""
                        @change="$emit('update:comp_shipping_address', shipping_address);"
                        :autocomplete="autocompleteValueLocal"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-combobox>
                </v-col>
                <v-col class="pt-0 pb-0">
                    <v-text-field 
                        class="to-upper"
                        :autocomplete="autocompleteValueLocal"
                        :disabled="isEditingAllowed || editShippingAddress"
                        v-model="shipping_zipcode" 
                        :label="$t('billings.shipping_zip_code')"
                        v-on:keydown.enter.prevent=""
                        v-on:keydown="handleInput($event,'alphanumeric',10);"
                        @change="$emit('update:comp_shipping_zipcode', shipping_zipcode);"
                        :error-messages="$store.state.validation && !$v.shipping_zipcode.required ? [this.$t('billings.text_messages.shipping_zipcode_required')] : [] "
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0">
                    <v-combobox
                        class="to-upper"
                        :disabled="isEditingAllowed || editShippingAddress"
                        v-model="shipping_city" 
                        @input.native="$emit('update:comp_shipping_city', search_shipping_city);fetchShippingCitySuggListDebounced()"
                        :items="shipping_cities"
                        item-value="id"
                        item-text="name"
                        :label="$t('billings.shipping_city')"
                        :search-input.sync="search_shipping_city"
                        :loading="isLoadingSearchShippingCity"
                        v-on:keydown.enter.prevent=""
                        v-on:keydown="handleInput($event,'alpha',50);"
                        @change="$emit('update:comp_shipping_city', shipping_city);"
                        :error-messages="$store.state.validation && !$v.shipping_city.required ? [this.$t('billings.text_messages.shipping_city_required')] : [] "
                        :autocomplete="autocompleteValueLocal"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-combobox>
                </v-col>
                <v-col class="pt-0 pb-0">
                    <v-combobox
                        class="to-upper"
                        :disabled="isEditingAllowed || editShippingAddress"
                        v-model="shipping_county" 
                        @input.native="$emit('update:comp_shipping_county', search_shipping_county);fetchShippingCountySuggListDebounced()"
                        :items="shipping_counties"
                        item-value="county"
                        item-text="county"
                        :label="$t('billings.shipping_county')"
                        :search-input.sync="search_shipping_county"
                        :loading="isLoadingSearchShippingCounty"
                        v-on:keydown.enter.prevent=""
                        v-on:keydown="handleInput($event,'alpha',50);"
                        @change="$emit('update:comp_shipping_county', shipping_county);"
                        :autocomplete="autocompleteValueLocal"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-combobox>
                </v-col>
                <v-col class="pt-0 pb-0">
                    <v-autocomplete
                        class="to-upper"
                        :autocomplete="autocompleteValueLocal"
                        :disabled="isEditingAllowed || editShippingAddress"
                        flat
                        :items="shipping_countries"
                        v-model="shipping_country"
                        :label="$t('billings.shipping_country')"
                        @change="$emit('update:comp_shipping_country', shipping_country);"
                        item-value="id"
                        item-text="key"
                        v-on:keydown.enter.prevent=""
                        :loading="isLoadingSearchShippingCountry"
                        :error-messages="$store.state.validation && !$v.shipping_country.required ? [this.$t('billings.text_messages.shipping_country_required')] : [] "
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-no-data
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </template>
        <edit-client-model v-if="openEditClientModel" @buttonClicked="updatedClient" :defaultClient="company_client_name" :showDialog.sync="openEditClientModel"></edit-client-model>
    </v-card>
</template>
<style scoped>
    .client_name{
        font-size: 14px;
        margin: 0px;
    }
    .client_detail{
        font-size: 11px;
        margin-bottom: 2px;
    }
</style>
<script>
import axios from "axios";
import _ from 'lodash';
import { API_BASE_URL } from "@/config";

import EditClientModel from '@/components/EditClientModel.vue';
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha } from 'vuelidate/lib/validators'


export default {
    components: {
        "edit-client-model" :EditClientModel
    },
    mixins: [validationMixin],
    validations: {
        company_client_name: { 
            required
        },
        company_client_gsm: {
            maxLength: maxLength(45)
        },
        company_client_phone: {
            maxLength: maxLength(45)
        },
        familyClient: { 
            required
        },
        company_client_email: {
            email: (val) => email((val ?? "").toLowerCase()),
        },
        denomination: {
            required: requiredIf(function (model) {
                return this.entityType.id == 1;
            }),
        },
        tva: {
            required: requiredIf(function (model) {
                return this.entityType.id == 1;
            }),
        },
        zipcode: {
            required: requiredIf(function (model) {
                return this.address;
            }),
        },
        city: {
            required: requiredIf(function (model) {
                return this.address;
            }),
        },
        country: {
            required: requiredIf(function (model) {
                return this.address;
            }),
        },
        shipping_zipcode: {
            required: requiredIf(function (model) {
                return this.shipping_address;
            }),
        },
        shipping_city: {
            required: requiredIf(function (model) {
                return this.shipping_address;
            }),
        },
        shipping_country: {
            required: requiredIf(function (model) {
                return this.shipping_address;
            }),
        },
    },
    props: {
            comp_company_client_first_name: [String, Object],
            comp_entityType:  [String, Object],
            comp_client_id: [String, Number],
            comp_company: [String ],
            comp_company_client_name: [String, Object],
            comp_search_client: [String, Number],
            comp_company_client_phone: [String, Number],
            comp_company_client_gsm: [String, Number],
            comp_company_client_fax: [String, Number],
            comp_company_client_email: [String, Number],
            comp_web: [String, Number],
            comp_currency: [String, Number],
            comp_representative_company_id: [String, Number],
            comp_user: [String, Number],
            comp_note: [String, Number],
            comp_familyClient:  [String, Number, Object],
            comp_denomination: [String, Number],
            comp_tva: [String, Number],
            comp_same_as: [Boolean,String, Number],
            
            comp_address: [String, Object, Number],
            comp_city: [String, Object, Number],
            comp_country: [String, Object, Number],
            comp_company_client_language: [String, Object, Number],
            comp_county: [String, Object, Number],
            comp_zipcode: [String, Number],

            comp_shipping_address: [String, Object, Number],
            comp_shipping_city: [String, Object, Number],
            comp_shipping_country: [String, Object, Number],
            comp_shipping_county: [String, Object, Number],
            comp_shipping_zipcode: [String, Number],
            comp_is_shipping_visible:[Boolean],
            comp_order_counts: [Object]
    },
    data() {
        return {
            openEditClientModel: false,
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            autocompleteValueLocal : 'off',
            loadingCitiesFirstTime: true,
            defaultFamilyClient: {},
            defaultCountry: {},
            entityType: this.comp_entityType,
            client_id: this.comp_client_id,
            editShippingAddress: false,
            search_client: '',
            search_client_first_name: '',
            clients: [],
            client:'',
            company: this.comp_company,
            company_client_phone: this.comp_company_client_phone,
            company_client_gsm: this.comp_company_client_gsm,
            company_client_fax: this.comp_company_client_fax,
            company_client_email: this.comp_company_client_email,
            web: this.comp_web,
            currency: this.comp_currency,
            representative_company_id: this.comp_representative_company_id,
            user: this.comp_user,
            note: this.comp_note,
            tva: this.comp_tva,
            same_as: false,
            company_client_names: [],
            company_client_name: this.comp_company_client_name,
            company_client_first_name: this.comp_company_client_first_name,
            familyClient: this.comp_familyClient,
            language: this.comp_company_client_language,

            addresses:[],
            address: this.comp_address,
            search_address:'',
            isLoadingSearchAddress: false,
            shipping_addresses:[],
            shipping_address: this.comp_shipping_address,
            search_shipping_address:'',
            isLoadingSearchShippingAddress: false,

            zipcode: this.comp_shipping_zipcode,
            shipping_zipcode: this.comp_shipping_zipcode,
            
            cities:[],
            city: this.comp_city,
            search_city:'',
            isLoadingSearchCity: false,
            shipping_cities:[],
            shipping_city: this.comp_shipping_city,
            search_shipping_city:'',
            isLoadingSearchShippingCity: false,

            counties:[],
            county: this.comp_county,
            search_county:'',
            isLoadingSearchCounty: false,
            shipping_counties:[],
            shipping_county: this.comp_shipping_county,
            search_shipping_county:'',
            isLoadingSearchShippingCounty: false,
            
            countries:[],
            country: this.comp_country,
            search_country:'',
            isLoadingSearchCountry: false,
            shipping_countries:[],
            shipping_country: this.comp_country,
            search_shipping_country:'',
            isLoadingSearchShippingCountry: false,

            token: this.$store.state.AccessToken,
            denominations: [],
            denomination: this.comp_denomination,
            isAutoSelect: false,
            languages: []
        }
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.entityType = {'id':0};
        
        this.header = { Authorization: 'Bearer ' + this.token };
        this.getCountries();
        this.getCompanyDenominations();
        this.setDefaultFamilyClient();
        this.getLanguages();
    },
    computed: {
        isCompanyEntitySelected () {
            if(this.entityType){
                return this.entityType.id === 1;
            }
            else{
                return false;
            }
            
        },
        isEditingAllowed(){
            // if(this.comp_order_counts){
            //     console.log("comp order counts",this.comp_order_counts.bill);
            //     return !(this.comp_order_counts && this.comp_order_counts.bill == 0 );
            // }
            return this.$store.state.isEditingAllowed;
        },
        isEditingAllowedBeforeBill(){
            if(this.comp_order_counts){
                console.log("comp order counts",this.comp_order_counts.bill);
                return !(this.comp_order_counts && this.comp_order_counts.bill > 0 );
            }
            return this.$store.state.isEditingAllowed;
        }
    },
    methods:{
        updatedClient(data){
            if(data){
                let isCompany = !!data.representative_company_id;
                this.entityType = isCompany ? this.entityTypeSelectItems[1] : this.entityTypeSelectItems[0];
                console.log("updated client response", data);
                // this.comp_company_client_name = data.name;
                this.company_client_name.name = data.name
                this.search_client = data.name;
                this.name = data.name;
                this.company_client_first_name = data.first_name;
                this.currency = data.currency; 
                this.company_client_email =  data.email;
                this.familyClient = data.familyClient;
                this.fax = data.fax;
                this.company_client_phone = data.phone;
                if(data.company){
                    this.denominations.forEach((element) => {
                        console.log("updated denominations", element, this.denominations, data.company);
                        if(element.id == data.company.denomination_id){
                            this.denomination = element.id;
                        }
                    })
                    this.tva = data.company.tva;
                }
                this.languages.forEach((element) => {
                    if(data && data.language && element.id == data.language.id){
                        console.log("language selected element---", this.language);
                        this.language = element;
                    }
                })
                console.log("language selected---", this.language);
                this.company_client_gsm = data.gsm;
                this.note  = data.note;
                this.web = data.web;
                this.emitValues();
            }
        },
        editClient(){
            if(this.comp_company_client_name){
                this.openEditClientModel = true
                // console.log("client name", this.comp_company_client_name);
                // this.$store.commit("seteditId",this.comp_company_client_name.id);
                // this.$router.push('/client/edit').catch(() => {});
            }
        },
        getLanguages(){
            this.$store.state.isLoading = true;
            axios.get(API_BASE_URL + '/languages', {headers: this.header})
                .then(({ data }) => {
                    this.languages = this.translateKeys(data.data, 'key', 'languages');
                })
                .catch(function (error) {
                
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        setDefaultFamilyClient(){
            this.familyClients.forEach(element => {
                if(element.is_default == 1){
                    this.defaultFamilyClient = element;
                    this.familyClient = this.defaultFamilyClient.id;
                }
            });
        },
        onResetPageClient(){
            this.client_id = null;
            this.search_client = null;
            this.search_client_first_name = null;
            this.client = null;
            this.company_client_name = null;
            this.company_client_names = [];
            this.company_client_first_name = null;
            this.company_client_phone = null;
            this.company_client_gsm = null;
            this.language = null;
            this.company_client_fax = null;
            this.company_client_email = null;
            this.web = null;
            this.currency = null;
            this.representative_company_id = null;
            this.user = null;
            this.note = null;
            this.tva = null;
            this.familyClient = null,
            this.address = null;
            this.search_address = null;
            this.zipcode = null;
            this.cities = [];
            this.city = null;
            this.search_city = null;
            this.counties = [];
            this.county = null;
            this.search_county = null;
            this.country = null;
            this.search_country = null;
            this.denomination = null;
            this.familyClient = this.defaultFamilyClient;
            this.country = this.defaultCountry;

            this.shipping_address = null;
            this.search_shipping_address = null;
            this.shipping_zipcode = null;
            this.shipping_cities = [];
            this.shipping_city = null;
            this.search_shipping_city = null;
            this.shipping_counties = [];
            this.shipping_county = null;
            this.search_shipping_county = null;
            this.shipping_country = null;
            this.search_shipping_country = null;
            this.shipping_country = this.defaultCountry;
            this.same_as = false;
            this.emitValues();
        },
        getAddresses () {
            axios.get(API_BASE_URL + '/addresses',{headers: this.header})
            .then(({ data }) => {
                this.addresses = data.data;
                this.shipping_addresses = data.data;
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            });
        },
        getCities () {
            axios.get(API_BASE_URL + '/cities',{headers: this.header})
            .then(({ data }) => {
                this.cities = data.data;
                if(this.loadingCitiesFirstTime){
                    this.shipping_cities = data.data;
                }
                this.loadingCitiesFirstTime = false;
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            });
        },
        getCounties () {
            axios.get(API_BASE_URL + '/counties',{headers: this.header})
            .then(({ data }) => {
                this.counties = data.data;
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            });
        },
        getCountries () {
            axios.get(API_BASE_URL + '/countries',{headers: this.header})
            .then(({ data }) => {
                this.countries = data.data;
                this.shipping_countries = data.data;
                this.countries.forEach(element => {
                    if(element.is_default == 1){
                        this.defaultCountry = element;
                    }
                });
                this.country = this.defaultCountry.id;
                this.shipping_country = this.defaultCountry.id;
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            });
        },
        getCompanyDenominations() {
            axios.get(API_BASE_URL + '/codes?class_key=company_denomination', {headers: this.header})
            .then(({ data }) => {
                this.denominations = this.translateKeys(data.data, 'key', 'company_denomination');
                
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            });
        },
        fetchAddressSuggListDebounced(){
            if(! this.search_address || typeof this.search_address == "object"){
                return ;
            }
            this.isLoadingSearchAddress = true;
            axios.get(API_BASE_URL + '/addresses?' + (this.search_address ? ('&search=' + this.search_address) : '') ,{headers: this.header})
            .then(({data}) => {
                this.addresses = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchAddress = false;
            })
            .finally(() => (
                this.isLoadingSearchAddress = false
            ))
        },
        fetchShippingAddressSuggListDebounced(){
            if(! this.search_shipping_address || typeof this.search_shipping_address == "object"){
                return ;
            }
            this.isLoadingSearchShippingAddress = true;
            axios.get(API_BASE_URL + '/addresses?' + (this.search_shipping_address ? ('&search=' + this.search_shipping_address) : '') ,{headers: this.header})
            .then(({data}) => {
                this.addresses = data.data;
                this.shipping_addresses = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchShippingAddress = false;
            })
            .finally(() => (
                this.isLoadingSearchShippingAddress = false
            ))
        },
        fetchClientsListDebounced(){
            if(! this.search_client || typeof this.search_client == "object"){
                return ;
            }
            if(! this.isEditingAllowed){ // This means it is in edit mode
                axios.get(API_BASE_URL + '/clients/search?searchRegNumber=true' + (this.search_client ? ('&search=' + this.search_client) : '') ,{headers: this.header})
                .then(({data}) => {
                    data.data.forEach((element) => {
                        element.clientname = element.name + ' ' + (element.first_name ?? '');
                    })
                    this.company_client_names = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchAddress = false;
                })
                .finally(() => (
                    this.isLoadingSearchAddress = false
                ))
            }
        },
        fetchClientsListDebouncedFromFirstName(){
            if(! this.search_client_first_name || typeof this.search_client_first_name == "object"){
                return ;
            }
            if(! this.isEditingAllowed){ // This means it is in edit mode
                axios.get(API_BASE_URL + '/clients/search?searchRegNumber=true' + (this.search_client_first_name ? ('&search=' + this.search_client_first_name) : '') ,{headers: this.header})
                .then(({data}) => {
                    data.data.forEach((element) => {
                        element.clientname = element.name + ' ' + (element.first_name ?? '');
                    })
                    this.company_client_names = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchAddress = false;
                })
                .finally(() => (
                    this.isLoadingSearchAddress = false
                ))
            }
        },
        fetchShippingCountySuggListDebounced(){
            if(! this.search_shipping_county || typeof this.search_shipping_county == "object"){
                return ;
            }

            this.isLoadingSearchShippingCounty = true;
            axios.get(API_BASE_URL + '/counties?' + (this.search_shipping_county ? ('&search=' + this.search_shipping_county) : '') ,{headers: this.header})
            .then(({data}) => {
                this.shipping_counties = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchShippingCounty = false;
            })
            .finally(() => (
                this.isLoadingSearchShippingCounty = false
            ))
        },
        fetchCountySuggListDebounced(){
            if(! this.search_county || typeof this.search_county == "object"){
                return ;
            }

            this.isLoadingSearchCounty = true;
            axios.get(API_BASE_URL + '/counties?' + (this.search_county ? ('&search=' + this.search_county) : '') ,{headers: this.header})
            .then(({data}) => {
                this.counties = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchCounty = false;
            })
            .finally(() => (
                this.isLoadingSearchCounty = false
            ))
        },
        fetchShippingCitySuggListDebounced(){
            if(! this.search_shipping_city || typeof this.search_shipping_city == "object"){
                return ;
            }

            this.isLoadingSearchShippingCity = true;
            axios.get(API_BASE_URL + '/cities?' + (this.search_shipping_city ? ('&search=' + this.search_shipping_city) : '') ,{headers: this.header})
            .then(({data}) => {
                this.shipping_cities = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchShippingCity = false;
            })
            .finally(() => (
                this.isLoadingSearchShippingCity = false
            ))
        },
        fetchCitySuggListDebounced(){
            if(! this.search_city || typeof this.search_city == "object"){
                return ;
            }

            this.isLoadingSearchCity = true;
            axios.get(API_BASE_URL + '/cities?' + (this.search_city ? ('&search=' + this.search_city) : '') ,{headers: this.header})
            .then(({data}) => {
                this.cities = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchCity = false;
            })
            .finally(() => (
                this.isLoadingSearchCity = false
            ))
        },
        fetchCountrySuggListDebounced(){
            if(! this.search_country || typeof this.search_country == "object"){
                return ;
            }
            axios.get(API_BASE_URL + '/countries?' + (this.search_country ? ('&search=' + this.search_country) : '') ,{headers: this.header})
            .then(({data}) => {
                this.countries = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchCountry = false;
            })
            .finally(() => (
                this.isLoadingSearchCountry = false
            ))
        },

        fetchShippingCountrySuggListDebounced(){
            if(! this.search_shipping_country || typeof this.search_shipping_country == "object"){
                return ;
            }
            axios.get(API_BASE_URL + '/countries?' + (this.search_shipping_country ? ('&search=' + this.search_shipping_country) : '') ,{headers: this.header})
            .then(({data}) => {
                this.shipping_countries = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchShippingCountry = false;
            })
            .finally(() => (
                this.isLoadingSearchShippingCountry = false
            ))
        },
        emitValues(){
            this.$emit('update:comp_entityType', this.entityType);
            this.$emit('update:comp_company_client_name', this.company_client_name);
            this.$emit('update:comp_company_client_first_name', this.company_client_first_name);
            this.$emit('update:comp_search_client', this.search_client);
            this.$emit('update:comp_company_client_phone', this.company_client_phone);
            this.$emit('update:comp_representative_company_id', this.representative_company_id);
            this.$emit('update:comp_company_client_gsm', this.company_client_gsm);
            this.$emit('update:comp_company_client_fax', this.company_client_fax);
            this.$emit('update:comp_company_client_email', this.company_client_email);
            this.$emit('update:comp_familyClient', this.familyClient);
            this.$emit('update:comp_denomination', this.denomination);
            this.$emit('update:comp_tva', this.tva);
            this.$emit('update:comp_same_as', this.same_as);
            this.$emit('update:comp_company_client_language', this.language);


            this.$emit('update:comp_address', this.address);
            this.$emit('update:comp_city', this.city);
            this.$emit('update:comp_county', this.county);
            this.$emit('update:comp_country', this.country);
            this.$emit('update:comp_zipcode', this.zipcode);

            this.$emit('update:comp_shipping_address', this.shipping_address);
            this.$emit('update:comp_shipping_city', this.shipping_city);
            this.$emit('update:comp_shipping_county', this.shipping_county);
            this.$emit('update:comp_shipping_country', this.shipping_country);
            this.$emit('update:comp_shipping_zipcode', this.shipping_zipcode);
        },
        sameAsBilling(val=true){
            console.log(val,"same as");
            if(val){
                this.shipping_address = this.address;
                this.shipping_zipcode = this.zipcode;
                this.shipping_city = this.city;
                this.shipping_county = this.county;
                this.shipping_country = this.country;
                this.editShippingAddress = true;
            }
            this.emitValues();
        }
        
    },
    watch: {
        language(val){
            this.$emit('update:comp_company_client_language', val);
        },
        familyClients(){
            this.setDefaultFamilyClient();
        },
        same_as: {
            handler: function(val){
                if(!val){
                    this.search_shipping_address = null;
                    this.shipping_address = null;
                    this.shipping_zipcode = null;
                    this.shipping_city = null;
                    this.shipping_country = null;
                    this.shipping_county = null;
                    this.editShippingAddress = false;
                }
                else{
                    this.sameAsBilling();
                }
                this.emitValues();
                this.comp_same_as = this.same_as;
            }
        },
        address:{
            handler: function(val){
                console.log("billing zipcode",val);
                if(val && val.city){
                    this.city = val.city;
                    this.$emit('update:comp_city', this.city);
                }
                if(val && val.county){
                    this.county = val.county;
                    this.$emit('update:comp_county', this.county);
                }
                if(val && val.city && val.city.country_id){
                    this.country = val.city.country_id;
                    this.$emit('update:comp_country', this.country);
                }
                if(val && val.zip){
                    this.zipcode = this.address ? this.address.zip : null;
                    this.$emit('update:comp_zipcode', this.zipcode);
                }
                //   this.sameAsBilling(this.same_as);
                
            }
        },
        shipping_address:{
            handler: function(val){
                if(val && val.city){
                    this.shipping_city = val.city;
                    this.$emit('update:comp_shipping_city', this.shipping_city);
                }
                if(val && val.county){
                    this.shipping_county = val.county;
                    this.$emit('update:comp_shipping_county', this.shipping_county);
                }
                if(val && val.city && val.city.country_id){
                    this.shipping_country = val.city.country_id;
                    this.$emit('update:comp_shipping_country', this.shipping_country);
                }
                if(val && val.zip){
                    this.shipping_zipcode = this.shipping_address ? this.shipping_address.zip : null;
                    this.$emit('update:comp_shipping_zipcode', this.shipping_zipcode);
                }
                
            }
        },
        comp_entityType:                {handler: function (){ this.entityType = {'id': this.comp_entityType.id};           }},
        comp_company_client_name:       {handler: function (){ this.company_client_name = this.comp_company_client_name; if(!this.company_client_name){this.company_client_names = [];}   }},
        comp_company_client_names:      {handler: function (){ this.company_client_names = this.comp_company_client_names;  }},
        comp_company_client_first_name: {handler: function (){ this.company_client_first_name = this.comp_company_client_first_name;  }},
        comp_search_client:             {handler: function (){ this.search_client = this.comp_search_client;                }},
        comp_company_client_phone:      {handler: function (){ this.company_client_phone = this.comp_company_client_phone;  }},
        comp_representative_company_id: {handler: function (){ this.representative_company_id = this.comp_representative_company_id;  }},
        comp_company_client_gsm:        {handler: function (){ this.company_client_gsm = this.comp_company_client_gsm;      }},
        comp_company_client_fax:        {handler: function (){ this.company_client_fax = this.comp_company_client_fax;      }},
        comp_company_client_email:      {handler: function (){ this.company_client_email = this.comp_company_client_email;  }},
        comp_familyClient:              {handler: function (){ this.familyClient = this.comp_familyClient;                  }},
        comp_denomination:              {handler: function (){ this.denomination = this.comp_denomination;                  }},
        comp_tva:                       {handler: function (){ this.tva = this.comp_tva;                                    }},
        comp_company_client_language:   {handler: function (){ console.log("language updated---", this.language, this.comp_company_client_language);this.language = this.comp_company_client_language;                                    }},
        

        comp_address:                   {handler: function (){ this.address = this.comp_address;this.search_address=this.comp_address; }},
        comp_city:                      {handler: function (){ this.isAutoSelect=true; this.city = this.comp_city;this.search_city=this.comp_city; this.comp_city = this.search_city; }},
        comp_county:                    {handler: function (){ this.county = this.comp_county;this.search_county=this.comp_county; this.comp_county = this.search_county; }},
        comp_country:                   {handler: function (){ this.country = this.comp_country;                            }},
        comp_zipcode:                   {handler: function (){ this.zipcode = this.comp_zipcode;                            }},

        comp_shipping_address:                   {handler: function (){ this.shipping_address = this.comp_shipping_address;this.search_shipping_address=this.comp_shipping_address; }},
        comp_shipping_city:                      {handler: function (){ this.isAutoSelect=true; this.shipping_city = this.comp_shipping_city;this.search_shipping_city=this.comp_shipping_city; this.comp_shipping_city = this.search_shipping_city; }},
        comp_shipping_county:                      {handler: function (){ this.shipping_county = this.comp_shipping_county;this.search_shipping_county=this.comp_shipping_county; this.comp_shipping_county = this.search_shipping_county; }},
        comp_shipping_country:                   {handler: function (){ this.shipping_country = this.comp_shipping_country;                            }},
        comp_shipping_zipcode:                   {handler: function (val){ this.shipping_zipcode = this.comp_shipping_zipcode;                            }},
        comp_same_as:                   {handler: function (val){ this.same_as = this.comp_same_as;                            }},
        familyClient(val){
            if(!val){
                this.familyClient = this.defaultFamilyClient.id;
            }
            this.$emit('update:comp_familyClient', this.familyClient);
        },

        zipcode(val){this.sameAsBilling(this.same_as); this.$emit('update:comp_zipcode', this.zipcode);},
        shipping_zipcode(val){ this.$emit('update:comp_shipping_zipcode', this.shipping_zipcode);},
        company_client_first_name(){
            this.emitValues();
        },
        company_client_name(val) {
            this.$v.company_client_name.$touch();
            if(val && typeof val == "object"){
                let isCompany = !!val.representative_company_id;
                this.entityType = isCompany ? this.entityTypeSelectItems[1] : this.entityTypeSelectItems[0];
                this.company_client_first_name = val.first_name;

                this.familyClient = val.family_client_id;
                this.company_client_email = val.email;
                this.company_client_gsm = val.gsm;
                console.log("language selected", val.language_id);
                this.languages.forEach((element) => {
                    if(element.id == val.language_id){
                        this.language = element;
                    } 
                })
                this.company_client_phone = val.phone;
                this.representative_company_id = val.representative_company_id;

				if(!this.comp_address &&  !this.comp_city){
					this.zipcode = val.zip;
					if(val.client_address.length > 0){
						this.zipcode = val.client_address[0].address ? val.client_address[0].address.zip : null;
						this.address = val.client_address[0].address ? val.client_address[0].address : null;
						this.city = val.client_address[0].address ? val.client_address[0].address.city : null;
						this.country = val.client_address[0].address ? val.client_address[0].address.city.country : null;
					}
					if(val.company_address.length > 0){
						this.zipcode = val.company_address[0].address ? val.company_address[0].address.zip : null;
						this.address = val.company_address[0].address ? val.company_address[0].address : null;
						this.city = val.company_address[0].address ? val.company_address[0].address.city : null;
						this.country = val.company_address[0].address ? val.company_address[0].address.city.country : null;
					}
				}
                
                
                if(val.company){
                    this.denomination = val.company.denomination_id;
                    this.tva = val.company.tva
                }
                this.emitValues();
            }
            
        },
        search_address (val) {
            if (!val || this.address == val) {
                return
            }
            
            this.addresses = [];
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchAddressSuggListDebounced()
            }, 500);
        },
        search_shipping_address (val) {
            if (!val || this.shipping_address == val) {
                return
            }
            
            this.shipping_addresses = [];
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchShippingAddressSuggListDebounced()
            }, 500);
        },
        search_client (val) {
            if (!val || this.client_id == val) {
                return
            }
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchClientsListDebounced()
            }, 500);
        },
        search_client_first_name (val) {
            if (!val || this.client_id == val) {
                return
            }
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchClientsListDebouncedFromFirstName()
            }, 500);
        },
        search_county (val) {
            if (!val || this.county == val) {
                return
            }
            
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchCountySuggListDebounced()
            }, 500);
        },
        search_city (val) {
            if (!val || this.city == val) {
                return
            }
            
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchCitySuggListDebounced()
            }, 500);
        },
        search_country (val) {
            if (!val || this.country == val) {
                return
            }
            this.isLoadingSearchCountry = true;
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchCountrySuggListDebounced()
            }, 500);
        },
        search_shipping_city (val) {
            if (!val || this.shipping_city == val) {
                return
            }
            
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchShippingCitySuggListDebounced()
            }, 500);
        },
        search_shipping_county (val) {
            if (!val || this.shipping_county == val) {
                return
            }
            
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchShippingCountySuggListDebounced()
            }, 500);
        },
        search_shipping_country (val) {
            if (!val || this.shipping_country == val) {
                return
            }
            this.isLoadingSearchShippingCountry = true;
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchShippingCountrySuggListDebounced()
            }, 500);
        },
        city(val){
            if(val && val.country_id){
                this.country = val.country_id;
            }
            this.sameAsBilling(this.same_as);
            this.$emit('update:comp_city', this.city);
            
        },
        county(val){
            console.log("county", val);
            this.sameAsBilling(this.same_as);
        },
        country(val){
            if(!val){
                this.country = this.defaultCountry.id;
            }
            this.sameAsBilling(this.same_as);
            this.$emit('update:comp_country', this.country);
        },
        shipping_city(val){
            if(val && val.country_id){
                this.shipping_country = val.country_id;
            }
            
        },
        shipping_country(val){
            if(!val){
                this.shipping_country = this.defaultCountry.id;
            }
            this.$emit('update:comp_shipping_country', this.shipping_country);
        },

    }
}
</script>