<template>
    <v-card flat>
        <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
        <confirmation-model
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <v-form ref="form"  class="main_form" @submit.prevent="submit">
        <v-container fluid class="mt-n8">
            <v-overlay :value="! isPageLoaded">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-row justify="space-between">
            <v-col class="d-flex pb-0" cols="12" sm="3">
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-if="checkTag('billing')"
                            :autocomplete="autocompleteValueLocal"
                            v-model="selectedBill"
                            class="mr-2 to-upper"
                            :items="bills"
                            :search-input.sync="search_bill"
                            item-text="bill_number"
                            item-value="id"
                            :loading="isLoadingSearchRegiClient"
                            :label="$t('scheduler.search_bill')"
                            :placeholder="$t('scheduler.start_typing')"
                            return-object
                            append-icon="mdi-close"
                            v-on:keydown="handleInput($event,'alphanumeric',20);"
                            v-on:keydown.enter.prevent=""
                            ref="billDropdown"
                            @click:append="onResetPageData(); search_bill= null;"   
                            :disabled="isEditingAllowed"
                            :outlined="is_boxes"
                            :hide-no-data="true"
                        >
                            <template slot="item" slot-scope="{ item }">
                                <v-list-item-content>
                                    <span class="pl-7 bill_type" v-if="item.type.key == 'bill'"> Bill </span>
                                    <span class="pl-7 bill_type" v-if="item.type.key == 'purchase_order'"> Purchase Order  </span>
                                    <span class="pl-7 bill_type" v-if="item.type.key == 'estimation'">  Estimation  </span>
                                    <span class="pl-7 bill_type" v-if="item.type.key == 'credit_note'"> Credit Note  </span>
                                    <span>
                                    <v-icon class="ma-0" v-if="item.type.key == 'bill'" color="red" right> mdi-script-text </v-icon>
                                    <v-icon class="ma-0" v-if="item.type.key == 'purchase_order'" color="green" right> mdi-tag-text </v-icon>
                                    <v-icon class="ma-0" v-if="item.type.key == 'estimation'" color="black" right> mdi-book-open </v-icon>
                                    <v-icon class="ma-0" v-if="item.type.key == 'credit_note'" color="black" right> mdi-note-multiple </v-icon>
                                    {{item.bill_number}}
                                    </span>
                                    <br/>
                                    <span class="pl-7 client_name" v-if="item.client">
                                    <template v-if="item.client.company"> Company - </template>
                                    <template v-else> Private - </template>
                                    {{item.client.name}}
                                    </span>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <!-- <v-row class="mt-2" v-if="appointmentdata && appointmentdata.interventions">
                            <v-col>
                                <span> {{ getInterventions(appointmentdata.interventions) }} </span>
                            </v-col>
                        </v-row> -->
                        <v-text-field
                            v-if="appointmentdata && appointmentdata.interventions"
                            readonly
                            disabled
                            :label="$t('scheduler.collaborators')"
                            :value="getInterventions(appointmentdata.interventions)"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            
            <v-col class="d-flex pb-0  justify-end" cols="12" sm="6">
                    <v-checkbox
                        class="mt-5 mr-3"
                        v-model="appointment_is_executed"
                        :disabled="checkIfPastDateAppointment()"
                        :label="$t('scheduler.is_work')"
                        style="min-width:100px"
                    ></v-checkbox>
                    <v-text-field
                        v-if="parent_company_client_name"
                        v-model="customer_balance"
                        :label="$t('billings.customer_balance')"
                        readonly
                        class="mr-2 to-upper"
                        :class="customer_balance > 0  ? 'customerbalance': ''"
                        v-on:keydown.enter.prevent=""
                        append-icon="mdi-information"
                        disabled
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    >
                        <template v-slot:prepend>
                            <v-tooltip
                                v-if="parent_company_client_name && parent_company_client_name.note && parent_company_client_name.note.length > 0"
                                bottom
                            >
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="warning"> mdi-information </v-icon>
                                </template>
                                {{ parent_company_client_name && parent_company_client_name.note ? parent_company_client_name && parent_company_client_name.note : '' }}
                            </v-tooltip>
                        </template>
                    </v-text-field>
                    <v-select
                        v-if="ispayment_method"
                        v-model="payment_method"
                        :items="payment_methods"
                        class="pr-2"
                        :label="$t('billings.payment_methods.title')"
                        item-text="text"
                        item-value="id"
                        return-object
                        v-on:keydown.enter.prevent=""
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-select>
                    <v-select
                        @click="filterOutStatuses"
                        v-if="isVisibleOrderStatus"
                        :disabled="selectedBill && selectedBill.status_id == 10251003"
                        v-model="order_status"
                        :items="billstatuses"
                        :label="$t('billings.status')"
                        item-text="text"
                        item-value="id"
                        return-object
                        v-on:keydown.enter.prevent=""
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-select>
                    <v-col class="d-flex pa-0 ma-0" cols="12" sm="4">
                        <v-menu
                            ref="menu"
                            v-model="menu_due_date"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            v-on:keydown.enter.prevent=""
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="due_date"
                                :label="$t('billings.due_date')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                v-on:keydown.enter.prevent=""
                                @focus="due_date=null"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                class="to-upper"
                            ></v-text-field>
                            </template>
                            <v-date-picker :first-day-of-week="1" :weekday-format="getDay" v-model="due_date" no-title scrollable :locale="userLanguageId">
                            <v-spacer></v-spacer>
                            
                            </v-date-picker>
                        </v-menu>
                    </v-col>
            </v-col>
            </v-row>

            <v-row class="group_label" v-if="checkTag('clients')">
                <v-col class=" pb-0">
                    <span style="color: #2196F3"> Client </span>
                </v-col>
            </v-row>
            
            <v-row class="pa-3 pt-6">
            <create-edit-client
                v-if="checkTag('clients')"
                ref="clientComp"
                :comp_entityType.sync="parent_entityType"
                :comp_client_id.sync="parent_client_id"
                :comp_company.sync="parent_company"
                :comp_company_client_name.sync="parent_company_client_name"
                :comp_company_client_names.sync="parent_company_client_names"
                :comp_search_client.sync="parent_search_client"
                :comp_company_client_phone.sync="parent_company_client_phone"
                :comp_company_client_gsm.sync="parent_company_client_gsm"
                :comp_company_client_fax.sync="parent_company_client_fax"
                :comp_company_client_email.sync="parent_company_client_email"
                :comp_web.sync="parent_web"
                :comp_currency.sync="parent_currency"
                :comp_representative_company_id.sync="parent_representative_company_id"
                :comp_user.sync="parent_user"
                :comp_note.sync="parent_note"
                :comp_familyClient.sync="parent_familyClient"
                :comp_denomination.sync="parent_denomination"
                :comp_tva.sync="parent_tva"
                :comp_address.sync="parent_address"
                :comp_country.sync="parent_country"
                :comp_city.sync="parent_city"
                :comp_county.sync="parent_county"
                :comp_zipcode.sync="parent_zipcode"


                :comp_shipping_address.sync="parent_shipping_address"
                :comp_shipping_country.sync="parent_shipping_country"
                :comp_shipping_city.sync="parent_shipping_city"
                :comp_shipping_county.sync="parent_shipping_county"
                :comp_shipping_zipcode.sync="parent_shipping_zipcode"
                :comp_same_as.sync="parent_address_same_as"
                :comp_is_shipping_visible="false"
                
            />
            </v-row>


            <v-row class="group_label" v-if="checkTag('projects')">
                <v-col class=" pb-0">
                    <span style="color: #2196F3"> Project </span>
                </v-col>
            </v-row>
            
            <v-row class="pa-3 pt-6">
                <create-edit-project
                    v-if="checkTag('projects')"
                    ref="clientComp"
                    :comp_entityType.sync="parent_entityType"
                    :comp_client_id.sync="parent_client_id"
                    :comp_company.sync="parent_company"
                    :comp_company_client_name.sync="parent_company_client_name"
                    :comp_company_client_first_name.sync="parent_company_client_first_name"
                    :comp_company_client_names.sync="parent_company_client_names"
                    :comp_search_client.sync="parent_search_client"
                    :comp_company_client_phone.sync="parent_company_client_phone"
                    :comp_company_client_gsm.sync="parent_company_client_gsm"
                    :comp_company_client_fax.sync="parent_company_client_fax"
                    :comp_company_client_email.sync="parent_company_client_email"
                    :comp_web.sync="parent_web"
                    :comp_currency.sync="parent_currency"
                    :comp_representative_company_id.sync="parent_representative_company_id"
                    :comp_user.sync="parent_user"
                    :comp_note.sync="parent_note"
                    :comp_familyClient.sync="parent_familyClient"
                    :comp_denomination.sync="parent_denomination"
                    :comp_tva.sync="parent_tva"
                    :comp_address.sync="parent_address"
                    :comp_country.sync="parent_country"
                    :comp_city.sync="parent_city"
                    :comp_county.sync="parent_county"
                    :comp_zipcode.sync="parent_zipcode"


                    :comp_shipping_address.sync="parent_shipping_address"
                    :comp_shipping_country.sync="parent_shipping_country"
                    :comp_shipping_city.sync="parent_shipping_city"
                    :comp_shipping_county.sync="parent_shipping_county"
                    :comp_shipping_zipcode.sync="parent_shipping_zipcode"
                    :comp_same_as.sync="parent_address_same_as"
                    :comp_is_shipping_visible="true"
                    
                />
            </v-row>

            <v-row class="group_label" v-if="checkTag('vehicles')">
            <v-col class="pb-0">
                <span style="color: #2196F3"> Vehicle </span>
            </v-col>
            </v-row>

            <v-row class="pa-3 pt-6"> 
                <create-edit-vehicle
                    v-if="checkTag('vehicles')"
                    ref="vehicleComp"
                    :comp_vehicle_type.sync="parent_vehicle_type"
                    :comp_registration_no.sync="parent_registration_no"
                    :comp_brand.sync="parent_brand"
                    :comp_model.sync="parent_model"
                    :comp_km.sync="parent_km"
                    :comp_reg_date.sync="parent_reg_date"
                    :comp_creditor.sync="parent_creditor"
                    :comp_creditors.sync="creditors"
                    :comp_vin.sync="parent_vin"
                    :comp_is_leasing.sync="parent_is_leasing"
                    :comp_client_detail.sync="parent_company_client_name"
                />
            </v-row>
            
            <v-row  align="center" v-if="checkTag('appointments')">
                <v-col cols="12" sm="2">
                    <t-date-time-field 
                        :label="$t('scheduler.date_time')" 
                        dateFormat="yyyy-MM-dd" 
                        v-model="datetime" 
                        append-icon="mdi-calendar" 
                        readonly
                    ></t-date-time-field>
                </v-col>
                <v-col cols="11" sm="1">
                    <v-dialog ref="dialog" v-model="timepickermodal" :return-value.sync="time" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="time"
                                :label="$t('scheduler.time_to')"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="to-upper"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </template>
                        <v-time-picker v-if="timepickermodal" v-model="time" full-width format="24hr" :min="starttime">
                            <v-spacer></v-spacer>
                            <v-btn color="grey lighten-2" @click="timepickermodal = false">
                                {{$t('cancel')}}
                                <v-icon dark right> mdi-keyboard-return </v-icon>
                            </v-btn>
                            <v-btn color="success" @click="$refs.dialog.save(time)">
                                {{$t('ok')}}
                                <v-icon dark right> mdi-check </v-icon>
                            </v-btn>
                        </v-time-picker>
                    </v-dialog>
                </v-col>
                <v-col class="shrink" >
                    <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                            <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                            <v-card-text class="pa-0">
                                <v-color-picker v-model="color" mode="hexa" flat />
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-combobox
                        :autocomplete="autocompleteValueLocal"
                        v-model="work_type" 
                        :items="work_types"
                        item-value="id"
                        item-text="name"
                        class="to-upper"
                        :label="$t('scheduler.work_type')"
                        :error-messages="$store.state.validation && !$v.work_type.required ? [this.$t('scheduler.text_messages.work_type_required')] : []"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-combobox>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-combobox
                        :autocomplete="autocompleteValueLocal"
                        v-model="work_resource" 
                        @change="setWorkType"
                        :items="workresources"
                        item-value="id"
                        item-text="name"
                        class="to-upper"
                        :label="$t('scheduler.work_resource')"
                        :error-messages="$store.state.validation && !$v.work_resource.required ? [this.$t('scheduler.text_messages.work_resource_required')] : [] "
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-combobox>
                </v-col>
                
                <v-col cols="auto" md="1">
                    <v-combobox
                        :autocomplete="autocompleteValueLocal"
                        v-model="notification_type" 
                        :items="notification_types"
                        item-value="id"
                        class="to-upper"
                        item-text="text"
                        :label="$t('scheduler.reminder')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-combobox>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field 
                        class="to-upper"
                        v-model="free_text"
                        :label="warning_note ? $t('scheduler.warning_note') : $t('scheduler.free_text')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    >
                    
                        <template v-slot:prepend>
                            <v-checkbox
                                class="mt-n1"
                                v-model="warning_note"
                            ></v-checkbox>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col sm="1" md="1" v-if="selectedArticles && selectedArticles.length > 0">
                    <v-dialog v-model="note_dialog" persistent max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark v-bind="attrs" v-on="on">
                                {{ $t('scheduler.add_bill_note') }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ $t('scheduler.add_bill_note') }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                        <v-textarea
                                            clear-icon="mdi-close-circle"
                                            v-model="bill_note_temp"
                                            :label="$t('scheduler.note')"
                                            class="to-upper"
                                        ></v-textarea>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="pr-2">
                                <v-spacer></v-spacer>
                                <v-btn color="grey-lighten-2" @click="closeAddNoteModel()">
                                    {{ $t('receptions.modify_article.cancel') }}
                                    <v-icon dark right> mdi-keyboard-return </v-icon>
                                </v-btn>
                                <v-btn color="green" dark @click="saveAddNoteModel()">
                                    {{ $t('receptions.modify_article.save') }}
                                    <v-icon dark right> mdi-content-save </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row >
                <v-col sm="11" md="11">
                    <template v-if="selectedBill">
                        <span>{{ $t('scheduler.order_number') }} <a  @click="editBill(selectedBill)">{{ selectedBill.bill_number }}</a></span> 
                        <span class="pl-4">{{ $t('scheduler.creation_date') }} {{ selectedBill.created_at }}</span>
                    </template>
                </v-col>
                <!-- <v-spacer></v-spacer> -->
            </v-row>
            <v-row class="" v-if="checkTag('billing')">
                <v-data-table
                    :footer-props="{
                    'items-per-page-options': dataTableOptions
                    }"
                    :headers="dataHeader"
                    :hide-default-footer="true"
                    :hide-default-header="true"
                    :items="selectedArticles"
                    :items-per-page="50"
                    class="elevation-0"
                    style="width: 100%"
                    disable
                >
                <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                    <tr>
                    <template v-for="header in headers" >
                        <th :key="header.value" scope="col" class="text-right">
                        <template v-if="header.value == 'article_original_name'">
                            <v-row  justify="space-between">
                            <v-col class="d-flex" cols="12" sm="2">
                                {{ header.text }}
                            </v-col>
                            <v-col v-if="selectedFamilyArticle && selectedFamilyArticle.is_stock_managed == 1"  cols="12" sm="3" class="ma-1 pt-0 pb-0" v-on:keydown.enter.prevent="parentInternalStockOnly = !parentInternalStockOnly">
                                <v-checkbox
                                    class="mt-1"
                                    hide-details="auto"
                                    v-model="parentInternalStockOnly"
                                    :label="$t('scheduler.is_only_internal')"
                                ></v-checkbox>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else-if="header.value == 'actions'">
                            <v-row  justify="space-between">
                                <v-col class="d-flex dontbreakline" cols="12" sm="2">
                                    {{ header.text }}
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            {{ header.text }}
                        </template>
                        </th>
                    </template>
                    </tr>
                </thead>
                </template>
                <template v-slot:no-data>
                    <span></span>
                </template>
                <template v-slot:body.prepend>
                    <tr class="first_row">
                        <td>
                        </td>
                        <td class="pl-4 pr-2" style="width: 1000px !important">
                            <v-row>
                            
                            <search-article
                                :class="article ? 'articleset': 'articlenotset'"
                                @changeDialog="changeDialog()" 
                                :compSelectedArticleId.sync="parentSearchSelectedArticleId"
                                :internalStockOnly.sync="parentInternalStockOnly"
                                :selectedFamilyArticle.sync="selectedFamilyArticle"
                            ></search-article>
                            
                            <v-text-field
                                v-if="typeof article == 'object'"
                                :autocomplete="autocompleteValueLocal"
                                class="ma-0 pa-0 selectedArticleTextBox to-upper"
                                append-icon="mdi-close"
                                @click:append="resetSelectedArticle()"
                                v-model="article.title_description[0].description"
                                hide-details="auto"
                                v-on:keydown.enter.prevent="addArticleToDataTable()"
                                v-on:keydown.prevent="jumpToField($event, 'tableQuantity')"
                                v-on:keydown.tab.prevent="jumpToField($event, 'tableQuantity')"
                                outlined
                                dense
                            ></v-text-field>
                            

                            <!-- <v-col>
                                <v-autocomplete
                                    :autocomplete="autocompleteValueLocal"
                                    :disabled="isRecalculatingTableData"
                                    v-model="article"
                                    class="pa-0 article_search to-upper"
                                    :items="articles_list"
                                    :loading="isLoadingArticle"
                                    hide-no-data
                                    hide-details="auto"
                                    hide-selected
                                    item-text="title_description[0].description"
                                    item-value="id"
                                    placeholder="Start typing to Search"
                                    outlined
                                    dense
                                    return-object 
                                    no-filter 
                                    tabindex="1"
                                    ref="tableArticleSearch"
                                    readonly
                                    @focus="showDialog()"
                                    v-on:keydown.enter.prevent=""
                                    v-on:keypress="checkIfFamilyClientEmpty($event)"
                                    v-on:keydown.tab.prevent="jumpToField($event, 'tableQuantity')"
                                    @change="jumpToField($event, 'tableQuantity')"
                                    >
                                    <template slot="item" slot-scope="{ item }">
                                        <v-list-item-content>
                                            <v-row>
                                            <v-col class="d-flex" cols="12" sm="10">
                                                <p class='article_name' style="padding-left:5px">{{item.title_description[0].description}}
                                                <br/>
                                                <span class="identifier">{{item.identifier}}</span>
                                                </p>
                                            </v-col>
                                            <template v-if="item.internal_company_article && item.internal_company_article.length > 0">
                                                <v-col class="d-flex justify-end" style="margin-left:-10px" cols="12" sm="2">
                                                <span style="color:red" v-if="item.internal_company_article[0].stock  <= 0">{{ item.internal_company_article[0].stock   }}</span>
                                                <span style="color:#dede1d" v-if="item.internal_company_article[0].stock   >= 1 && item.stock <= 5 ">{{ item.internal_company_article[0].stock   }}</span>
                                                <span style="color:green" v-if="item.internal_company_article[0].stock   > 5 ">{{ item.internal_company_article[0].stock   }}</span>
                                                </v-col>
                                            </template>
                                            <template v-else>
                                                <v-col class="d-flex justify-end" style="margin-left:-10px" cols="12" sm="2">
                                                <span style="color:red"> 0 </span>
                                                </v-col>
                                            </template>                              
                                            </v-row>
                                            
                                            
                                        </v-list-item-content>
                                    </template>
                                    </v-autocomplete>
                            </v-col> -->
                            </v-row>
                        </td>
                        <td align ="right" class="pl-2 pr-0" v-on:keydown.tab.prevent="jumpToField('', 'tableUnitPrice')" v-on:keydown.enter.prevent="" v-on:keydown.enter="addArticleToDataTable()" v-on:keydown="handleInput($event, selectedFamilyArticle ? (selectedFamilyArticle.quantity_decimals > 0 ? 'float' : 'int') : 'int',6);">
                            <custom-money-input
                            :autocomplete="autocompleteValueLocal"
                            :disabled="isRecalculatingTableData"
                            class="pt-0 to-upper right-aligned-input shrink"
                            hide-details="auto"
                            outlined
                            dense
                            tabindex="2"
                            style="width: 95px"
                            ref="tableQuantity"
                            v-model="quantity"
                            v-bind:properties="{
                                prefix: selectedFamilyArticle ? (selectedFamilyArticle.mu.value) : '',
                                readonly: false,
                                disabled: false,
                                outlined: true,
                            placeholder: selectedFamilyArticle ? (selectedFamilyArticle.quantity_decimals > 0 ? makeZeros(selectedFamilyArticle.quantity_decimals) : '0') : '0',
                                dense: true,
                                hideDetails:'auto',
                            }"
                            v-bind:options="{
                                locale: userLanguageId,
                                length: 8,
                                precision: selectedFamilyArticle ? (selectedFamilyArticle.quantity_decimals > 0 ? selectedFamilyArticle.quantity_decimals : 0) : 0,
                                empty: selectedFamilyArticle ? (selectedFamilyArticle.quantity_decimals > 0 ? makeZeros(selectedFamilyArticle.quantity_decimals) : '0') : '0',
                            }"
                            />
                        </td>
                        <td align ="right" class="pl-1 pr-0" v-on:keydown.enter="addArticleToDataTable()" v-on:keydown.exact="checkIfMinusPressed($event)" v-on:keydown.tab.prevent="jumpToField($event, 'tableRedPercentage')" >
                            <custom-money-input
                            :autocomplete="autocompleteValueLocal"
                            :disabled="isRecalculatingTableData"
                            class="pt-0 to-upper right-aligned-input shrink"
                            hide-details="auto"
                            outlined
                            dense
                            tabindex="3"
                            style="width:100px"
                            ref="tableUnitPrice"
                            v-model="unit_price"
                            v-bind:properties="{
                                prefix: currencySymbol,
                                readonly: false,
                                disabled: false,
                                outlined: true,
                                placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                dense: true,
                                hideDetails:'auto'
                            }"
                            v-bind:options="{
                                locale: userLanguageId,
                                length: 6,
                                precision: getDecimalNumber(),
                                empty: getDecimalNumber() == 2 ? 0.00 : 0.000,
                            }"
                            />
                        </td>
                        <td 
                            align="right" 
                            class="pl-1 pr-0" 
                            v-on:keydown.enter="addArticleToDataTable()" 
                            v-on:keydown.tab.prevent="addArticleToDataTable()"
                        >
                                <custom-money-input
                                :autocomplete="autocompleteValueLocal"
                                :disabled="isRecalculatingTableData"
                                v-model="red_percentage"
                                :background-color="!$v.red_percentage.maxValue ? '#ffc2b7' : (!$v.red_percentage.minValue ? '#ffc2b7' : 'white') "                      
                                v-bind:properties="{
                                    suffix: '%',
                                    readonly: false,
                                    disabled: false,
                                    outlined: true,
                                    clearable: false,
                                    placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                    dense: true,
                                    hideDetails:'auto'
                                }"
                                v-bind:options="{
                                    locale: userLanguageId,
                                    length: 3,
                                    precision: getDecimalNumber(),
                                    empty: getDecimalNumber() == 2 ? 0.00 : 0.000,
                                }"
                                class="pt-0 to-upper right-aligned-input shrink"
                                hide-details="auto"
                                outlined
                                dense
                                tabindex="4"
                                style="width:80px"
                                v-bind:focus="true"
                                ref="tableRedPercentage"
                                >
                                </custom-money-input>
                        </td>
                        <!-- <td style="text-align:right">{{ first_row_tariff }}</td> -->
                        <!-- <td style="text-align:right">{{ first_row_tva }}</td> -->
                        <td align="right" class="pl-1 pr-0">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="first_row_tva"
                                class="pt-0 to-upper right-aligned-input shrink"
                                style="width:70px"
                                hide-details="auto"
                                dense
                                outlined
                                required
                                v-on:keydown.enter.prevent=""
                            ></v-text-field>
                        </td>
                        <td class="pl-1 pr-0" style="text-align:right" v-on:keyup="reverseCalculate(null , first_row_thtva)" v-on:keydown.enter="addArticleToDataTable()" v-on:keydown.tab.prevent="addArticleToDataTable()">
                            <custom-money-input
                            :autocomplete="autocompleteValueLocal"
                            :disabled="isRecalculatingTableData"
                            class="pt-0 to-upper right-aligned-input shrink"
                            hide-details="auto"
                            outlined
                            dense
                            tabindex="3"
                            style="width:100px"
                            ref="tableFirstRowThtva"
                            v-model="first_row_thtva"
                            v-bind:properties="{
                                prefix: currencySymbol,
                                readonly: false,
                                disabled: false,
                                outlined: true,
                                placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                dense: true,
                                hideDetails:'auto',
                            }"
                            v-bind:options="{
                                locale: userLanguageId,
                                length: 6,
                                precision: getDecimalNumber(),
                                empty: getDecimalNumber() == 2 ? 0.00 : 0.000,
                            }"
                            />
                        </td>
                        <td class="pl-1 pr-0" style="text-align:right"  v-on:keyup="reverseCalculate(first_row_total , null)" v-on:keydown.enter="addArticleToDataTable()"  v-on:keydown.tab.prevent="addArticleToDataTable()">
                            <custom-money-input
                            :autocomplete="autocompleteValueLocal"
                            :disabled="isRecalculatingTableData"
                            class="pt-0 to-upper right-aligned-input shrink"
                            hide-details="auto"
                            outlined
                            dense
                            tabindex="3"
                            style="width:100px"
                            ref="tableFirstRowTotal"
                            v-model="first_row_total"
                            v-bind:properties="{
                                prefix: currencySymbol,
                                readonly: false,
                                disabled: false,
                                outlined: true,
                                placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                dense: true,
                                hideDetails:'auto',
                            }"
                            v-bind:options="{
                                locale: userLanguageId,
                                length: 6,
                                precision: getDecimalNumber(),
                                empty: getDecimalNumber() == 2 ? 0.00 : 0.000,
                            }"
                            />
                        </td>
                        <td>
                        <v-btn color="primary" dark class="outlinedbuttonheight" @click="addArticleToDataTable()">
                            <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
                <template v-slot:item.information="{ item }">
                    <v-tooltip bottom z-index="9999" v-if="item.article.family && item.article.family.is_stock_managed == 1" >
                    <template v-slot:activator="{on, attrs }">
                        <v-icon
                        v-if="item.article.internal_company_article && item.article.internal_company_article.length > 0  && item.article.internal_company_article[0].stock < item.quantity"
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        >
                        mdi-information-outline
                        </v-icon>
                    </template>
                    <span>{{ $t('billings.text_messages.article_does_not_have_stock') }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.article_original_name="{ item }">
                    <v-row>
                        <v-col cols="12" sm="12">
                            <template v-if="! item.text">
                            {{ item.article_original_name.title_description[0].description }}<br>
                            <span> {{ item.article_original_name.identifier }} </span>
                            </template>
                            <template v-if="item.text">
                            <div v-html="item.text"></div>
                            </template>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.quantity="{ item }">
                    <v-row>
                    <v-col cols="12" sm="12">
                        <template v-if="item.quantity">
                        <!-- {{ item.article_original_name }} -->
                        <span> 
                            {{ fixDecimal(item.quantity,item ? (
                                item.article_original_name ? item.article_original_name.family.quantity_decimals : 0
                            ) : 0) }} 
                            {{ item.article_original_name ? item.article_original_name.family.mu.value : 'pc.'}}  
                            <!-- Here we have hardcoded if nothing is their then family mu -->
                        </span>
                        <!-- <span> {{ item.quantity }} </span> -->
                        </template>
                    </v-col>
                    </v-row>
                </template>
                <template v-slot:item.rounded_unit_price="{ item }">
                    <v-row>
                    <v-col cols="12" sm="12">
                            <span v-if="billType.key == 'credit_note'">
                                <!-- {{ item.rounded_unit_price ? (parseFloat(item.rounded_unit_price) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }} -->
                                {{ (parseFloat(item.rounded_unit_price)).toFixed(getDecimalNumber()) }}
                            </span>
                            <span v-else> 
                                {{ item.rounded_unit_price ? (parseFloat(item.rounded_unit_price)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }}
                            </span>
                    </v-col>
                    </v-row>
                </template>
                <template v-slot:item.red_percentage="{ item }">
                    <v-row>
                    <v-col cols="12" sm="12">
                            <span> 
                                {{ (item.red_percentage ? item.red_percentage : 0).toFixed(getDecimalNumber()) }}
                            </span>
                    </v-col>
                    </v-row>
                </template>
                <template v-slot:item.tva="{ item }">
                    <v-row>
                    <v-col cols="12" sm="12">
                            <span> 
                                {{ (item.tva ? item.tva : 0).toFixed(getDecimalNumber()) }}
                            </span>
                    </v-col>
                    </v-row>
                </template>
                <template v-slot:item.thtva="{ item }">
                    <v-row>
                    <v-col cols="12" sm="12">
                            <span v-if="billType.key == 'credit_note'">
                                <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                                {{ (item.thtva).toFixed(getDecimalNumber()) }}
                            </span>
                            <span v-else> 
                                {{ (item.thtva ? item.thtva : 0).toFixed(getDecimalNumber()) }}
                            </span>
                    </v-col>
                    </v-row>
                </template>
                <template v-slot:item.total="{ item }">
                    <v-row>
                    <v-col cols="12" sm="12">
                            <span v-if="billType.key == 'credit_note'">
                                <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                                {{ (item.total).toFixed(getDecimalNumber()) }}
                            </span>
                            <span v-else> 
                                {{ (item.total ? item.total : 0).toFixed(getDecimalNumber()) }}
                            </span>
                    </v-col>
                    </v-row>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-col cols="9" sm="9">
                            <!-- {{ selectedArticles[item.srno ? item.srno  : 0].dots }} -->
                                <template v-if="selectedArticles[item.srno ? item.srno  : 0] && Array.isArray(selectedArticles[item.srno ? item.srno  : 0].dots)  && selectedArticles[item.srno ? item.srno  : 0].dots.length >= 0">
                                    <v-autocomplete
                                        v-if="item.article_original_name && item.article_original_name.family && (item.article_original_name.family.id == 1 || item.article_original_name.family.parent_family_id == 1) && (selectedArticles[item.srno ? item.srno  : 0].depot_out_id == null && selectedArticles[item.srno ? item.srno  : 0].depot_in_id == null)"
                                        v-model="selectedArticles[item.srno ? item.srno  : 0].stock_property"
                                        :items="selectedArticles[item.srno ? item.srno  : 0].dots ? selectedArticles[item.srno ? item.srno  : 0].dots : []"
                                        item-text="property"
                                        item-value="property"
                                        hide-details="auto"
                                        single-line
                                        :placeholder="$t('billings.dot')"
                                        class="pt-0 to-upper "
                                        dense
                                        tabindex="2"
                                        style="width:90px"
                                        @focus="$event.target.select()"
                                        ref="dot"
                                        append-icon=""
                                        v-on:keydown.enter.prevent=""
                                        v-on:keydown="handleInput($event,'int',4);fineDataForSending()"
                                        :outlined="is_boxes"
                                    >
                                        <template slot="item" slot-scope="{ item }">
                                            <template>
                                            {{ item.property }} X {{ item.counter }}
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </template>
                                <template v-else>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-progress-circular
                                            v-bind="attrs" 
                                            v-on="on"
                                            indeterminate
                                            color="primary"
                                            :size="20"
                                            :width="3"
                                            ></v-progress-circular>
                                        </template>
                                            <span> {{ $t('please_wait_while_we_are_loading_dots') }} </span>
                                    </v-tooltip>
                                </template>
                        </v-col>
                        <v-col  v-if="! (selectedBill && selectedBill.status_id == 10251003)" cols="3" sm="3" class="pa-0 pt-4">
                            <v-icon small color="red" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:body.append>
                    <tr v-if="selectedArticles && selectedArticles.length > 0" style="background-color:rgba(142, 142, 142, 0.07)">
                    <th class="text-sm-right" v-text="''" />
                    <th class="text-sm-right" v-text="''" />
                    <th class="text-sm-right" v-text="''" />
                    <th class="text-sm-right" v-text="''" />
                    <th class="text-sm-right" v-text="''" />
                    <!-- <th class="text-sm-right" v-text="''" /> -->
                    <th class="text-sm-right" v-text="''" />
                    <th class="text-sm-right">
                        <span v-if="billType.key == 'credit_note'">
                            <!-- {{(articleTHTVATotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                            {{ (articleTHTVATotal).toFixed(getDecimalNumber()) }}
                        </span>
                        <span v-else>
                            {{(articleTHTVATotal ? articleTHTVATotal : 0).toFixed(getDecimalNumber())}}
                        </span>
                    </th>
                    <th class="text-sm-right">
                    <span v-if="billType.key == 'credit_note'">
                        <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                        {{ (articleTotal).toFixed(getDecimalNumber()) }}
                    </span>
                    <span v-else>
                        {{(articleTotal ? articleTotal : 0).toFixed(getDecimalNumber())}}
                    </span>
                    </th>
                    <th class="text-sm-right" v-text="''" />
                    </tr>
                </template>
                </v-data-table>
                
        </v-row>
        </v-container>
        </v-form>
        
        <depot-add-modify-tyre
            v-if="depotInMod == true"
            :depotDetails="null"
            :vehicleDriverDetails.sync="vehicleDriverDetails"
            @modifyClose="modifyDepotInClose"
        ></depot-add-modify-tyre>
        <depot-out
            v-if="depotOutMod == true"
            :vehicleDriverDetails.sync="vehicleDriverDetails"
            :alreadySelectedDepot="selectedArticles"
            @modifyClose="modifyDepotOutClose"
        ></depot-out>
        <loading-model
            :showLoadingModel.sync="showLoadingModel"
        ></loading-model>

        <confirmation-model
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <v-btn @click="submit()" class="d-none" id="submit-scheduler"></v-btn>
        <v-btn @click="onDelete()" class="d-none" id="delete-scheduler"></v-btn>
        <v-btn @click="depotIn()" class="d-none" id="depot-in"></v-btn>
        <v-btn @click="depotOut()" class="d-none" id="depot-out"></v-btn>

        <v-btn @click="onResetPageData();goBackToListing()" class="d-none" id="reset-page-data"></v-btn>
        <v-btn @click="confirmPurchaseOrderPopUp()" class="d-none" id="make-purchase-order"></v-btn>
        <v-btn @click="confirmMakeBillPopUp()" class="d-none" id="make-bill"></v-btn>
        <v-btn @click="confirmCreditNotePopUp()" class="d-none" id="make-credit-note"></v-btn>
        <v-btn @click="printBill()" class="d-none" id="print-bill"></v-btn>
        <v-btn @click="emailBill()" class="d-none" id="email-bill"></v-btn>
        <v-btn @click="worksheetBill()" class="d-none" id="worksheet-bill"></v-btn>
        <v-btn @click="printReportFromTopButton()" class="d-none" id="print-report"></v-btn>
    </v-card>
</template>
<style scoped>
    .first_row:hover:not(.v-table__expanded__content) {
        background: white !important;
    }
    .supplier_name{
        font-size: 12px;
        margin: 0px;
    }
    .article_name{
        font-size: 14px;
        margin-bottom: 2px;
    }
    .bill_number{
        font-size: 14px;
        margin: 0px;
    }
    .identifier{
        font-size: 12px;
        margin-bottom: 2px;
    }
    .client_name{
        font-size: 11px;
        margin-bottom: 2px;
    }
    .bill_type{
        font-size: 11px;
        margin-bottom: 2px;
    }
</style>
<script>
    import axios from "axios";
    import _ from 'lodash';
    import 'vuejs-noty/dist/vuejs-noty.css'
    import { API_BASE_URL } from "@/config";
    import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from '@/config';
    import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
    import CreateEditClient from "@/components/Client/CreateEditClient";
    import CreateEditProject from "@/components/Projects/CreateEditProject";
    import CreateEditVehicle from "@/components/Vehicles/CreateEditVehicle";
    import DateTimePicker from "@/components/DateTimePicker";
    import {SCHEDULER_MODE} from "@/config";
    import {SCHEDULER_EDIT_MODE} from "@/config";

    import { validationMixin } from 'vuelidate'
    import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha, minValue, maxValue } from 'vuelidate/lib/validators'
    import SearchArticle from "@/components/Billing/SearchArticle";
    import { DateTime } from 'luxon';

    import DepotAddModifyTyre from "@/components/TyreHotel/DepotAddModifyTyre";
    import DepotOut from "@/components/TyreHotel/DepotOut";
    import LoadingModel from "@/components/LoadingModel";
    import ConfirmationModel from "@/components/ConfirmationModel";

    export default {
    mixins: [validationMixin],
    validations: {
        // Client Validation
        // Vehicle Validation
        parent_familyClient:{
            required
        },
        red_percentage: {
            maxValue: maxValue(100),
            minValue: minValue(0)
        },
        // free_text: {
        //     required: requiredIf(function (model) {
        //         return this.selectedArticles.length <= 0;
        //     }),
        // },
        work_resource: {
            required: required,
        },
        work_type: {
            required: required,
        },
    },
    name: "Scheduler",
    props: {
        iCompany: Boolean
    },
    components: {
            "create-edit-client" : CreateEditClient,
            "create-edit-vehicle": CreateEditVehicle,
            "search-article" : SearchArticle,
            "t-date-time-field" : DateTimePicker,
            "depot-add-modify-tyre":DepotAddModifyTyre,
            "depot-out": DepotOut,
            "loading-model": LoadingModel,
            "confirmation-model":	ConfirmationModel,
            "custom-money-input": CutsomMoneyInput,
            "create-edit-project": CreateEditProject
        },
    data () {
        return {
            selectedFamilyArticle: null,
            resourceType: null,
            bill_note_temp: null,
            bill_note: null,
            note_dialog: false,
            appointment_is_executed: false,
            billstatuses: [],
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            creditNoteMultiplicationValue: null,
            storedDepotIn: [],
            depotInMod: false,
            depotOutMod: false,
            parentSearchSelectedArticleId : null,
            parentInternalStockOnly: true,
            newValidation: false,
            dataTableOptions:                     DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            page:                                 1,
            lastPage:                             null,
            totalItems:                           null,
            itemsPerPage:                         DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            sortBy:                               'description',
            sortDesc:                             false,
            options:                              {},
            pagination:                           {},
            DeleteConfirm: false,
            denominations: [],
            allStatuses: [],
            order_status:'',
            isVisibleOrderStatus: false,
            isRecalculatingTableData: false,
            payment_methods:[],
            payment_method:null,
            ispayment_method: false,
            operation: 'edit',
            bill_amount:                           '',
            creditors:                          [],
            showLoadingModel:                   false,
            
            rdv_date:                           new Date().toISOString().substr(0, 10),
            due_date:                           new Date().toISOString().substr(0, 10),
            menu_rdv_date:                      false,
            menu_due_date:                      false,


            note:                               '',
            tvas:                               [],
            reference_client:                   '',
            isPageLoaded:                       false,
            unit_price:                         0.00,
            quantity:                           4,
            first_row_tariff:                   0,
            first_row_tva:                      '',
            cached_first_row_tva:               '',
            first_row_thtva:                    '',
            first_row_total:                    '',
            red_percentage:                     0.00,
            next_event_codes:                   [],
            next_event_code:                    '',
            search:                             '',
            isLoadingArticle:                   false,
            isLoading:false,
            rowNumber:                          0,
            articleTHTVATotal:                  0.00,
            articleTotal:                       0.00,
            selectedArticles:                   [],
            finedSelectedArticles:              [],
            articles:                           [],
            article:                            '',
            search_article:                     '',
            search_bill:                        '',
            allowContinueSearch:                true,
            bills:                              [],
            selectedBill:                       '',
            isLoadingSearchRegiClient:          false,
            familyArticle:                      '',
            customer_balance:                   0,

            // Vehicle Component Sync Values
            parent_vehicle_type: null,
            parent_registration_no: null,
            parent_brand: null,
            parent_model: null,
            parent_km: null,
            parent_reg_date: null,
            parent_creditor: null,
            parent_vin: null,
            parent_is_leasing: false,

            // Client Component Sync Values
            parent_entityType:null,
            parent_client_id:null,
            parent_company:null,
            parent_company_client_name:null,
            parent_company_client_names:[],
            parent_search_client: null,
            parent_company_client_phone:null,
            parent_company_client_gsm:null,
            parent_company_client_fax:null,
            parent_company_client_email:null,
            parent_web:null,
            parent_currency:null,
            parent_representative_company_id:null,
            parent_user:null,
            parent_note:null,
            parent_familyClient:null,
            parent_denomination:null,
            parent_registration_code:null,
            parent_tva:null,

            parent_address:null,
            parent_city:null,
            parent_county:null,
            parent_country:null,
            parent_zipcode:null,
            parent_address_same_as:false,


            parent_shipping_address:null,
            parent_shipping_city:null,
            parent_shipping_county:null,
            parent_shipping_country:null,
            parent_shipping_zipcode:null,

            dialog: false,

            token: this.$store.state.AccessToken,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            autocompleteValueLocal : 'off',
            dataHeader: [
                    { text: ''                             ,          align: 'right',  width:5,    sortable: false,         value: 'information' },
                    { text: this.$t('scheduler.article'),              align: 'start',  width:450,  sortable: true,          value: 'article_original_name'},
                    { text: this.$t('scheduler.quantity'),             align: 'end',    width:80,  sortable: true,          value: 'quantity' },
                    // { text: this.$t('scheduler.unit_price'),           align: 'end',    width:80,  sortable: false,         value: 'unit_price' },
                    { text: this.$t('billings.unit_price'),           align: 'end',    width:80,  sortable: false,         value: 'rounded_unit_price' },
                    { text: this.$t('scheduler.red_percentage'),       align: 'end',    width:80,  sortable: false,         value: 'red_percentage' },
                    // { text: this.$t('scheduler.tarrif'),               align: 'end',    width:100,  sortable: false,         value: 'tarrif' },
                    { text: this.$t('scheduler.tva'),                  align: 'end',    width:100,  sortable: false,         value: 'tva' },
                    { text: this.$t('scheduler.thtva'),                align: 'end',    width:125,  sortable: false,         value: 'thtva' },
                    { text: this.$t('scheduler.total'),                align: 'end',    width:125,  sortable: false,         value: 'total' },
                    { text: this.$t('billings.actions') +'      .',              align: 'center', width:200,  sortable: false,         value: 'actions' },
            ],
            currencySymbol: this.getCurrencySymbol('EUR'),
            color: null,
            mask: '!#XXXXXXXX',
            menu: false,
            timepickermodal: false,
            time: null,
            datetime: DateTime.local().toISO(),
            workresources: [],
            storedworkresources: [],
            work_resource: null,
            work_types: [],
            stored_work_types: [],
            work_type: null,
            warning_note: false,
            free_text: null,
            billType: null,
            appointmentdata: null,
            reminder: null,
            starttime: null,
            endtime:   null,
            notification_types: [],
            notification_type: null,
            
            confirmationDialog: false,
            confirmationDialogConfirmationText:null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            selectedDepot: null,
            pauseWatchForUnitPrice: false
        }
    },
    mounted () {
        this.header = { Authorization: 'Bearer ' + this.token },
        this.creditNoteMultiplicationValue = this.getCompanyPropertyFromLocalStorage('CREDIT_NOTE_MULTIPLICATION_VALUE') ?? this.creditNoteMultiplicationDefaultValue;
        this.setBillType();
        this.getWorkTypes();
        this.getPaymentMethods();
        this.getCreditors();
        this.getTvas();
        this.getOrderStatuses();
        this.getNotificationTypes();
        this.$store.commit('setShowPrintReportInPrintDropDown', false);
    },
    activated(){
        this.setCurrentFormOperation();
        this.setPageData();
        this.setBillType();
        this.$store.commit('setShowPrintReportInPrintDropDown', false);
    },
    beforeDestroy() {
        this.$store.commit("setBillCancelVisible",false);
        this.$store.commit("setEditingAllowed",false);
        this.$store.commit("setMakeBillVisible",false);
        this.$store.commit("setMakePurchaseOrderVisible",false);
        this.$store.commit("setMakeCreditNoteVisible",false);
    },
    methods: {
    closeAddNoteModel(){
        this.bill_note_temp = this.bill_note;
        this.note_dialog = false;
    },
    saveAddNoteModel(){
        this.bill_note = this.bill_note_temp;
        if(this.appointmentdata){ // Only Call API if appointment is already selected
            let data = {
                note            : this.bill_note ?? "",
                appointment:{
                    id: this.appointmentdata ? this.appointmentdata.id : null,
                },
            };
            axios.put(API_BASE_URL + '/appointments/' + data.appointment.id, (data), {headers: this.header})
            .then(response => {
                if (response.status === 200) {
                    this.$noty.success( this.$t('scheduler.text_messages.schedule_updated') , { timeout: 5000, layout: 'bottomLeft'});
                }
                else {
                    this.$noty.error(response, { timeout: 5000, layout: 'bottomLeft'});
                }
            })
            .catch((error) => {
                if(error.response.data.errors[0]){
                    this.$noty.error(this.$t('scheduler.text_messages.' + error.response.data.errors[0]), { timeout: 5000, layout: 'bottomLeft'});
                }
                else{
                    this.$noty.error(this.$t('scheduler.text_messages.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
                }
            })
            .finally(()=>{
                this.$store.state.isLoading = false;
                this.note_dialog = false;
            });
        }
        else{
            this.note_dialog = false;
        }
    },
    checkIfPastDateAppointment(){
        if(this.appointmentdata){
            let today = new Date()
            let appointmetDate = new Date(this.appointmentdata.start_time)
            return ! (appointmetDate.getDate() == today.getDate() && appointmetDate.getMonth() == today.getMonth() && appointmetDate.getFullYear() == today.getFullYear());
        }
        else if (this.$route.name == "AddScheduler"){
            return false;
        }
        return true;
    },
    reverseCalculate(total, thtva){
        this.pauseWatchForUnitPrice = true;
        if(total){
            // x = total/quantity(1+ tva/100)
            // (unit price * quantity) * ( 1 -  red_percentage /100)( 1 + tva /100)
            console.log("total", total, this.quantity);
            this.unit_price = parseFloat(total / (this.quantity * ( 1 - this.red_percentage/100) *  ( 1 + this.first_row_tva/ 100) ));
            this.rounded_unit_price = parseFloat(total / (this.quantity * ( 1 + this.first_row_tva/ 100) )).toFixed(this.getDecimalNumber());
            this.calculateFields(true, true, true, false);
        }
        if(thtva){
            //  thtva = unit_price * quantity (1 - r/100 )
            this.unit_price = parseFloat(thtva / (this.quantity * ( 1 - this.red_percentage/100)));
            this.rounded_unit_price = parseFloat(thtva / this.quantity).toFixed(this.getDecimalNumber());
            console.log("THTVA", this.thtva / this.quantity);
            this.calculateFields(true, true, false, true);
        }
    },
    getInterventions(interventions){
        let shortInterventions = "";
        for (let index = 0; index < interventions.length; index++) {
            if(interventions[index] && interventions[index].user && interventions[index].user.short_name){
                shortInterventions +=  interventions[index].user.short_name + " / ";
            }
        }
        return shortInterventions.slice(0, -2);
    },
    getProposedArticles(article_id, family_article_id, family_client_id, quantity){
        axios.get(API_BASE_URL + '/articles/proposed/' + article_id + 
            '?family_article_id=' + family_article_id + 
            (family_client_id ? '&family_client_id=' + family_client_id : '')
        ,{headers: this.header})
        .then(({data}) => {
            
                data.data.forEach((proposedArticle) => {
                    console.log("article to add" , proposedArticle);
                    let unit_price = proposedArticle.price ?? 0.00;
                    let rounded_unit_price = proposedArticle.price ?? 0.00;
                    let first_row_tariff = parseFloat(proposedArticle.article.tariff_price ?? 0);
                    let first_row_thtva = 0;
                    let first_row_total = 0;
                    let red_percentage = 0;
                    let thtva = parseFloat((unit_price * quantity) - ( (unit_price * quantity) * 0.00) /100);
                    console.log("proposed article after", proposedArticle.article);
                    
                    // check if article already exists in the datatable
                    let alreadyExistsProposedArticle = this.selectedArticles.find((element,index) => {
                        if( typeof element.article == "object" && element.article.id == proposedArticle.article.id){
                            this.selectedArticles.splice(index, 1);
                            return element;
                        }
                        if( typeof element.article == "number" && element.article == proposedArticle.article.id){
                            this.selectedArticles.splice(index, 1);
                            return element;
                        }
                    });
                    if(alreadyExistsProposedArticle){
                        // recalculate the row total
                        quantity = parseFloat(quantity) + parseFloat(alreadyExistsProposedArticle.quantity);
                        unit_price = parseFloat(unit_price);
                        // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                        first_row_thtva = parseFloat((unit_price * quantity) - ( (unit_price * quantity) * red_percentage) /100);
                        first_row_total = parseFloat(parseFloat(first_row_thtva) + parseFloat((this.first_row_tva * first_row_thtva ) / 100));
                    }

                    let isToMultiplyWithCredNoteMulValue = false;
                    if(this.billType && this.billType.key == "credit_note"){
                        isToMultiplyWithCredNoteMulValue = true;
                    }

                    this.selectedArticles.push({
                        srno: this.rowNumber++,
                        article: proposedArticle.article,
                        article_original_name: proposedArticle.article,
                        quantity: quantity,
                        unit_price: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (unit_price ?? 0)) : (unit_price ?? 0)),
                        rounded_unit_price: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (rounded_unit_price ?? 0)) : (rounded_unit_price ?? 0)).toFixed(this.getDecimalNumber()),
                        red_percentage:  (red_percentage),
                        tarrif: (proposedArticle.article.tariff_price ?? 0),
                        tva: parseFloat(this.first_row_tva),
                        thtva: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (thtva ?? 0)) : (thtva ?? 0)),
                        stock_property: null,
                        total: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (parseFloat(parseFloat(thtva) + parseFloat((this.first_row_tva * thtva ) / 100)) ?? 0)) : (parseFloat(parseFloat(thtva) + parseFloat((this.first_row_tva * thtva ) / 100)) ?? 0)),
                        text: null,
                        row:  0,
                        text_position: 0,
                        depot_out_id: null,
                        depot_in_id: null,
                        dots: null
                    });
                    this.resetSrNo();
                })
            
        })
        .catch((err) => {
            console.log(err);
        })
    },
    showOrHidePrintReport(){
        if(this.selectedBill){
            let found = false;
            this.selectedBill.details.forEach((order_detail) => {
                if(order_detail.depot_out_id || order_detail.depot_in_id){
                    found = true;
                }
            });
            console.log("show or hide print report", found);
            if(found){
                this.$store.commit('setShowPrintReportInPrintDropDown', true);
            }
            else{
                this.$store.commit('setShowPrintReportInPrintDropDown', false);
            }
        }
        else{
            this.$store.commit('setShowPrintReportInPrintDropDown', false);
        }
    },
    confirmPrintReportPopUp(){
        this.confirmationDialogConfirmationText = this.$t("billings.print_report_confirmation");
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = 'print_report';
        this.confirmationDialog = true;
    },
    confirmPrintLabelPopUp(){
        this.confirmationDialogConfirmationText = this.$t("billings.print_label_confirmation");
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = 'print_label';
        this.confirmationDialog = true;
    },
    confirmMakeBillPopUp(){
        this.confirmationDialogConfirmationText = this.$t("billings.convert_to_bill_confirmation");
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = 'make_bill';
        this.confirmationDialog = true;
    },
    confirmPurchaseOrderPopUp(){
        this.confirmationDialogConfirmationText = this.$t("billings.convert_to_purchase_order_confirmation");
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = 'make_purchase_order';
        this.confirmationDialog = true;
    },
    confirmCreditNotePopUp(){
        this.confirmationDialogConfirmationText = this.$t("billings.convert_to_credit_note_confirmation");
        this.confirmationDialogFalseText = this.$t("no");
        this.confirmationDialogTrueText = this.$t("yes");
        this.confirmationDialogOperation = 'make_credit_note';
        this.confirmationDialog = true;
    },
    printReport(){
        window.open(API_BASE_URL + '/depot/'+ this.selectedDepot.md5_id +'/print?document=report&lang='+ (localStorage.getItem('languageId') || 'en')  + "&user=" + localStorage.getItem('loginUserId'), "_blank");
    },
    printLabel(){
        window.open(API_BASE_URL + '/depot/'+ this.selectedDepot.md5_id +'/print?document=labels&lang='+ (localStorage.getItem('languageId') || 'en')  + "&user=" + localStorage.getItem('loginUserId'), "_blank");
    },
    modifyDepotOutClose(modifyDialog, data){
        console.log("after closing dialog", modifyDialog, data);
        if(data){
            this.addDepotArticleToDataTable(data, 'out');
        }
        
        this.depotOutMod = false;
    },
    modifyDepotInClose(modifyDialog, data){
        this.depotInMod = false;
        if(data){
            this.selectedDepot = data;
            this.storedDepotIn.push(this.selectedDepot.id);
            this.parent_registration_no.depot.push(data);
            this.confirmPrintLabelPopUp();
            this.addDepotArticleToDataTable(data,'in');
        }
    },
    depotIn(){
        this.selectedDepot = null;
        const clientData = this.makeNewClientData();
        const vehicleData = this.makeNewVehicleData();
        this.addClient(clientData).then(() => {
            this.createVehicle(vehicleData).then(() => {
            this.depotInMod = true;
            });
        });
    
    },
    depotOut(){
        const clientData = this.makeNewClientData();
        const vehicleData = this.makeNewVehicleData();
        this.addClient(clientData).then(() => {
            this.createVehicle(vehicleData).then(() => {
            this.depotOutMod = true;
            })
        });
    
    },
    makeNewClientData(){
        return {
            name: this.parent_company_client_name ? (typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.name : this.parent_company_client_name) : null,
            family_client_id: this.parent_familyClient          ? (typeof this.parent_familyClient    == "object" ? parseInt(this.parent_familyClient.id) : this.parent_familyClient )  :  null,
            denomination_id: this.parent_denomination          ?? null,
            email: this.parent_company_client_email  ?? null,
            phone: this.parent_company_client_phone  ?? null,
            gsm: this.parent_company_client_gsm    ?? null,
            fax: this.parent_company_client_fax    ?? null,
            web: this.parent_web                   ?? null,
            tva: this.parent_tva                   ?? null,
            note: this.parent_note                  ?? null,
        };
    },
    addClient(data) {
        return new Promise((resolve, reject) => {
            if( !(this.parent_company_client_name ? (typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.id : null) : null)){
            this.showLoadingModel = true;
            axios.post(API_BASE_URL + '/clients', data, {headers: this.header})
            .then(response => {
                console.log("add client data", response.data.data);
                this.parent_company_client_name = response.data.data;
                resolve();
            })
            .catch((error) =>{
                reject();
            })
            .finally(() => {
                this.showLoadingModel = false;
            });
            }
            else{
            resolve();
            }
        })
    
    },
    makeNewVehicleData(){
        return {
            client_id:    this.parent_company_client_name ? (typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.id : null) : null,
            vehicle_type_id  : this.parent_vehicle_type ? (typeof this.parent_vehicle_type  == "object" ? this.parent_vehicle_type.id : null) : null,
            registration_number : this.parent_registration_no && typeof this.parent_registration_no == "object" ? this.parent_registration_no.registration_number : this.parent_registration_no,
            brand_id           : this.parent_brand ? this.parent_brand.id  : null,
            model           : this.parent_model ?? null,
            km              : this.parent_km ?? null,
            registration_date        : this.parent_reg_date ?? null,
            vin             : this.parent_vin ?? null,
            is_leasing      : this.parent_is_leasing ? (this.parent_is_leasing == false ? 0 : 1 ) : null,
            creditor_id     : this.parent_creditor ? (typeof this.parent_creditor == "object" ? this.parent_creditor.id : this.parent_creditor) : null,
        }
    },
    createVehicle(data) {
        data.client_id = this.parent_company_client_name ? (typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.id : null) : null;
        return new Promise((resolve, reject) => {
            if( !(this.parent_registration_no && typeof this.parent_registration_no == "object" ? this.parent_registration_no.id : null)){
            this.showLoadingModel = true;
            axios.post( API_BASE_URL + '/client_vehicles', data, {headers: this.header})
            .then(response => {
                this.parent_registration_no = response.data;
                resolve();
            })
            .catch((error) => {
                reject();
            })
            .finally(() => {
                this.showLoadingModel = false;
            })
            }
            else{
            resolve();
            }
        });
    },
    debounceInput: _.debounce(function (val) {
    
    }, 500),
    
    setPageData(){
            this.$store.commit("setValidation",false); // enable validation
            this.autocompleteValueLocal = this.autocompleteValue();
            this.$store.commit('setFormMode', SCHEDULER_MODE);
            this.onResetPageData();
            // this.notification_type = this.notification_types[0];
            this.getWorkResources().then(() => {
                if (this.$route.name == "EditScheduler" ) {
                    let appointment = this.$store.state.appointment;
                    if(appointment.resourceType){
                        this.resourceType = appointment.resourceType;
                        this.setWorkResources();
                    }
                    if(appointment.id){
                        this.fetchAppointmentByID(appointment.id);
                        this.$store.commit("setBillCancelVisible",true);
                        this.$store.commit("setEditingAllowed",true);
                        this.$store.commit('setFormMode', SCHEDULER_EDIT_MODE);
                    }
                    else{
                        this.$router.push('/scheduler/add').catch(() => {});
                        this.isPageLoaded = true;
                        this.onResetPageData();
                    }
                }
                else if(this.$route.name == "AddScheduler"){
                    
                    if(this.$store.state.appointment){
                        let appointment = this.$store.state.appointment;
                        console.log("work resource type", appointment);
                        if(appointment.resourceType){
                            this.resourceType = appointment.resourceType;
                            this.setWorkResources(false, true);
                        }
                        if(appointment.new_start_date_time){
                            this.datetime = new Date(appointment.new_start_date_time).toISOString();
                        }
                        
                        if(appointment.work_resource_id){
                            this.workresources.forEach((element) => {
                                if(element.id == appointment.work_resource_id){
                                    this.work_resource = element;
                                }
                            });
                        }
                    }
                    else{
                        this.setWorkResources(true, true);
                    }
                    this.isPageLoaded = true;
                }
                else{
                    this.isPageLoaded = true;
                    this.onResetPageData();
                }
            });
        },
        confirmationButtonClicked(buttonClicked){
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog=false;
        },
        deletePopUp(){
            this.confirmationDialogConfirmationText = this.$t("scheduler.delete_confirmation_for_appointment");
            this.confirmationDialogFalseText = this.$t("scheduler.cancel");
            this.confirmationDialogTrueText = this.$t("scheduler.delete");
            this.confirmationDialogOperation = 'delete';
            this.confirmationDialog = true;
        },
        getWorkTypes(){
            axios.get(API_BASE_URL + "/work_types", { headers: this.header })
            .then(({ data }) => {
                // this.work_types = data.data;
                this.stored_work_types = data.data;
                // this.work_type = this.work_types[0];
            })
            .catch(function (error) {
                console.log("an error occured " + error);
            });
        },
        editBill(item){
            this.$store.commit("seteditId",item.id);
            console.log(this.$store.state.editId,"commited Bill ID");
            this.$router.push('/billing/edit').catch(() => {});
        },
        /**
         * If setAllWorkResources means : Page is refreshed
         */
        setWorkResources(setAllWorkResources, setFirst){
            this.workresources = this.storedworkresources.filter((element) => {
                if(! setAllWorkResources){
                    if(this.resourceType && element.resource_type_id == this.resourceType.id){
                        return true;
                    }
                }
                else{
                    return true;
                }
                
                return false;
            });
            if(setFirst && this.workresources.length > 0){
                this.work_resource = this.workresources[0];
            }
        },
        getWorkResources(){
            return new Promise((resolve, reject) => {
                if(this.cachedData['work_resources'] && this.cachedData['work_resources'].length > 0){
                    this.storedworkresources = this.cachedData['work_resources'];
                    this.setWorkResources();
                    resolve();
                }
                else{
                    axios.get(API_BASE_URL + "/work_resources", { headers: this.header })
                    .then(({ data }) => {
                        this.storedworkresources = data.data;
                        this.cachedData['work_resources'] = data.data;
                        this.setWorkResources();
                        resolve();
                    })
                    .catch(function (error) {
                        console.log("an error occured " + error);
                        reject();
                    });
                }
            });
        },
        changeDialog(){
            this.dialog=false;
            this.$nextTick(() => 
                this.$refs['tableQuantity'].focus()
            )
        },
        resetSelectedArticle(){
            this.article='';
            this.unit_price = 0.00;
            this.red_percentage = 0.00;

        },
        showDialog(){
            this.parentSearchSelectedArticleId = null;
            this.dialog = true;
        },
        getTvas(){
            axios.get(API_BASE_URL + '/tvas?by_auth=1',{headers: this.header})
            .then(({data}) => {
                console.log("tva", data);
                // this.tvas.push(parseFloat(0));
                // data.forEach((item, index)  => {
                //   this.tvas.push(parseFloat(item.tax));
                // });
                
                // this.tvas = this.tvas.sort(function(a, b) {
                //   return b - a;
                // });
                this.first_row_tva = this.fixDecimal(data.tax);
                this.cached_first_row_tva = this.fixDecimal(data.tax);
            })
            .catch((err) => {
                console.log(err);
            })
        },
        fetchAppointmentByID(id, updateBill = true){
            if(id){
                axios.get(API_BASE_URL + '/appointments?id=' + id  ,{headers: this.header})
                .then(({data}) => {
                    console.log(data.data,"appointment order");
                    this.appointmentdata = data.data;
                    console.log("selected Bill", data.data);
                    if(data.data.order){
                        this.bills = [data.data.order];
                        if(updateBill){
                            this.selectedBill = this.bills[0];
                        }
                    }
                    // else{
                    //     this.selectedBill = null;
                    // }
                    this.$nextTick(() => 
                        this.$store.commit("setEditingAllowed",true)
                    )
                    
                    this.isPageLoaded = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingArticle = false;
                    this.isPageLoaded = true;
                    this.$router.push('/scheduler/add').catch(() => {});
                    
                })
                .finally(() => (
                this.isLoadingArticle = false,
                this.isPageLoaded = true
                ))
            }
            else{
                this.$router.push('/billing/add').catch(() => {});
                
            }
        
        },
        checkIfFamilyClientEmpty(event){
            if (!this.parent_familyClient){
                // this.$refs['clientComp'].$refs['family_client'].focus();
                this.$refs['clientComp'].$refs['family_client'].$touch;
                event.preventDefault();
            }
        },
        reCalculateSelectedArticlesPricing(){
            if(this.selectedArticles.length > 0){
                let selectedIds = this.selectedArticles.map((element) => {
                if(typeof element.article == "number"){ // saved bill case, on load
                    return element.article;
                }
                return element.article.article_id;
                });
                
                this.isRecalculatingTableData = true;
                this.$store.state.isLoading = true;
                axios.get(API_BASE_URL + '/billings/articles/search?page=1&items_per_page=10' + (this.parent_familyClient ? ('&family_client_for_pricing=' + this.parent_familyClient) : '') + (selectedIds.length > 0 ? ('&article_ids=' + selectedIds) : '')  ,{headers: this.header})
                .then(({data}) => {
                    if(data.data.length == 0){
                        this.$noty.error( this.$t('scheduler.appointment.no_pricing_found_for_this_article'), { timeout: 5000, layout: 'bottomLeft'});
                    }
                    this.rowNumber=0;
                    var tempSelectedArticles = [];

                    // mapping current selected articles with new articles and then updating the temporaray selected articles with new ... and rest of the things will be done by the watchers
                    this.selectedArticles.map((articleOldData) => {
                    let newMappedArticle = data.data.find((articleNewData) => {
                        

                        if(typeof articleOldData.article == "number"){  // saved bill case, on load
                        return articleNewData.id == articleOldData.article;
                        }
                        else{
                        return articleNewData.id == articleOldData.article.article_id;
                        }
                        
                    });
                    
                    if(newMappedArticle){
                        
                        let tariff = parseFloat(newMappedArticle.tariff_price ?? 0);
                        // let unit_price = parseFloat(newMappedArticle.internal_company_article ? newMappedArticle.internal_company_article[0].price : 0); // test it again
                        let unit_price = parseFloat(articleOldData.internal_company_article ? articleOldData.unit_price : 0); // test it again
                        let rounded_unit_price = parseFloat(articleOldData.internal_company_article ? articleOldData.rounded_unit_price : 0); // test it again
                        let tva = parseFloat(newMappedArticle.internal_company_article.length > 0  ? newMappedArticle.internal_company_article[0].tva.tax : 17);
                        let thtva = parseFloat((unit_price * articleOldData.quantity) - ( (unit_price * articleOldData.quantity) * articleOldData.red_percentage) /100);
                        let total = parseFloat(parseFloat(thtva) + parseFloat((tva * thtva ) / 100));
                        tempSelectedArticles.push({
                            srno: this.rowNumber++,
                            article: newMappedArticle,
                            article_original_name: newMappedArticle,
                            quantity: articleOldData.quantity,
                            unit_price: unit_price ?? 0,
                            rounded_unit_price: rounded_unit_price ?? 0, 
                            red_percentage: articleOldData.red_percentage ?? 0,
                            tarrif: tariff,
                            tva: tva,
                            stock_property: newMappedArticle.property ?? null,
                            thtva: thtva,
                            total: total,
                            family_article: newMappedArticle.family,
                            text: newMappedArticle.text,
                            row: newMappedArticle.row,
                            text_position: newMappedArticle.text_position ?? 0,
                            depot_out_id: newMappedArticle.depot_out_id,
                            depot_in_id: newMappedArticle.depot_in_id

                        });
                    }
                    });
                    // this.selectedArticles = tempSelectedArticles;
                })
                .catch((err) => {
                    this.$store.state.isLoading = false;
                    this.isRecalculatingTableData = false
                })
                .finally(
                this.$store.state.isLoading = false,
                this.isRecalculatingTableData = false
                )

            }
        },
        checkEmptinessOfForm(){

            let isBillFieldsEmpty = !this.order_status &&  !this.payment_method && !this.note && !this.reference_client && !this.red_percentage && !this.next_event_code  && this.selectedArticles.length == 0 ;

            let isClientFieldsEmpty =   !this.parent_company_client_name && !this.parent_search_client && !this.parent_company_client_phone && !this.parent_company_client_gsm  && !this.parent_company_client_email && !this.parent_zipcode && !this.parent_note &&  !this.parent_tva && !this.parent_address && !this.parent_city &&  !this.parent_county;

            let isVehicleFieldsEmpty = !this.parent_vehicle_type && !this.parent_registration_no && !this.parent_brand && !this.parent_model && !this.parent_km && !this.parent_reg_date && !this.parent_creditor && !this.parent_vin;

            if(isBillFieldsEmpty && isClientFieldsEmpty && isVehicleFieldsEmpty){
            this.$store.commit("setShowNewButton",true);
            }
            else{
                if(this.selectedBill){
                this.$store.commit("setShowNewButton",true);
                }
                else{
                this.$store.commit("setShowNewButton",false);
                }
            }
            
            if(this.selectedBill){
                this.$store.commit("setIsBillSelected",true);
                this.$store.commit("setSelectedBillData", this.selectedBill);
            }
            else{
                this.$store.commit("setIsBillSelected",false);
                this.$store.commit("setSelectedBillData", null);
            }
            
            // show save button if client is selected and at least one article is selected
            if(this.parent_company_client_name ){
                this.$store.commit("setShowSaveButton",true);
            }
            else{
                this.$store.commit("setShowSaveButton",false);
            }
        },
        jumpToField : function(event,ref) {
            console.log("focus on", ref);
            this.$refs[ref].focus();
        },
        checkIfMinusPressed(event){
            if(/^[/-]$/i.test(event.key)){
                this.unit_price = this.unit_price * parseInt(this.creditNoteMultiplicationValue.value);
            }
        },
        makePurchaseOrder(){
            if(this.selectedBill.bill_number){
                this.$store.state.isLoading = true;
                this.$store.commit("setMakePurchaseOrderVisible",false);
                axios.post(API_BASE_URL + '/billings/convert/'+this.selectedBill.id,{ id: this.selectedBill.id}, {headers: this.header})
                .then(response => {
                    if (response.status === 200) {
                    // this.onResetPageData();
                    this.selectedBill = response.data.data;
                    this.$noty.success( this.$t('billings.text_messages.converted_to_purchase_order_successfully'), { timeout: 5000, layout: 'bottomLeft'});
                    this.$store.commit("setMakeBillVisible",true);
                    this.$store.commit("setMakePurchaseOrderVisible",false);
                    this.$store.commit("setMakeCreditNoteVisible",false);
                    }
                    else {
                    this.$store.commit("setMakeBillVisible",false);
                    this.$store.commit("setMakePurchaseOrderVisible",true);
                    this.$store.commit("setMakeCreditNoteVisible",false);
                    this.$noty.error( this.$t('billings.text_messages.converted_to_purchase_order_failed'), { timeout: 5000, layout: 'bottomLeft'});
                    }
                })
                .catch((error) => {
                    this.$store.commit("setMakeBillVisible",false);
                    this.$store.commit("setMakePurchaseOrderVisible",true);
                    this.$store.commit("setMakeCreditNoteVisible",false);
                    this.$noty.error( this.$t('billings.text_messages.converted_to_purchase_order_failed'), { timeout: 5000, layout: 'bottomLeft'});
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            }
        },
        makeBill(){
            if(this.selectedBill.bill_number){
                this.$store.state.isLoading = true;
                this.$store.commit("setMakeBillVisible",false);
                axios.post(API_BASE_URL + '/billings/convert/'+this.selectedBill.id,{ id: this.selectedBill.id}, {headers: this.header})
                .then(response => {
                    if (response.status === 200) {
                        // this.onResetPageData();
                        this.selectedBill = response.data.data;
                        this.$noty.success( this.$t('billings.text_messages.converted_to_bill_successfully'), { timeout: 5000, layout: 'bottomLeft'});
                        this.$store.commit("setMakeBillVisible",false);
                        this.$store.commit("setMakePurchaseOrderVisible",false);
                        this.$store.commit("setMakeCreditNoteVisible",true);
                    }
                    else {
                        this.$store.commit("setMakeBillVisible",true);
                        this.$store.commit("setMakePurchaseOrderVisible",false);
                        this.$store.commit("setMakeCreditNoteVisible",false);
                        this.$noty.error( this.$t('billings.text_messages.converted_to_bill_failed'), { timeout: 5000, layout: 'bottomLeft'});
                    }
                })
                .catch((error) => {
                    this.$store.commit("setMakeBillVisible",true);
                    this.$store.commit("setMakePurchaseOrderVisible",false);
                    this.$store.commit("setMakeCreditNoteVisible",false);
                    this.$noty.error( this.$t('billings.text_messages.converted_to_bill_failed'), { timeout: 5000, layout: 'bottomLeft'});
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            }
        },
        makeCreditNote(){
            if(this.selectedBill.bill_number){
                this.$store.state.isLoading = true;
                this.$store.commit("setMakeCreditNoteVisible",false);
                axios.post(API_BASE_URL + '/billings/convert/'+this.selectedBill.id,{ id: this.selectedBill.id}, {headers: this.header})
                .then(response => {
                    if (response.status === 200) {
                        // this.onResetPageData();
                        this.selectedBill = response.data.data;
                        this.$noty.success( this.$t('billings.text_messages.converted_to_credit_note_successfully'), { timeout: 5000, layout: 'bottomLeft'});
                        this.$store.commit("setMakeBillVisible",false);
                        this.$store.commit("setMakePurchaseOrderVisible",false);
                        this.$store.commit("setMakeCreditNoteVisible",false);
                    }
                    else {
                        this.$store.commit("setMakeBillVisible",false);
                        this.$store.commit("setMakePurchaseOrderVisible",false);
                        this.$store.commit("setMakeCreditNoteVisible",true);
                        this.$noty.error( this.$t('billings.text_messages.converted_to_credit_note_failed'), { timeout: 5000, layout: 'bottomLeft'});
                    }
                })
                .catch((error) => {
                    this.$store.commit("setMakeBillVisible",false);
                    this.$store.commit("setMakePurchaseOrderVisible",false);
                    this.$store.commit("setMakeCreditNoteVisible",true);
                    this.$noty.error( this.$t('billings.text_messages.converted_to_credit_note_failed'), { timeout: 5000, layout: 'bottomLeft'});
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
            }
        },
        printBill(){
            this.$store.commit("setValidation",true); // enable validation
            if(this.validateSubmit()){
                const data = this.makeSubmitData();
                if (! data.appointment.id) {
                    this.addSchedule(data,false).then(() => {
                        window.open(API_BASE_URL + '/billings/'+ this.selectedBill.md5_id +'/print?document=order&lang='+ (localStorage.getItem('languageId') || 'en')  + "&user=" + localStorage.getItem('loginUserId'), "_blank");
                    });
                } else {
                    this.updateSchedule(data,false).then(() => {
                        window.open(API_BASE_URL + '/billings/'+ this.selectedBill.md5_id +'/print?document=order&lang='+ (localStorage.getItem('languageId') || 'en')  + "&user=" + localStorage.getItem('loginUserId'), "_blank");
                    });
                }
                // this.$noty.success( this.$t('billings.text_messages.credit_note_updated_successfully'), { timeout: 5000, layout: 'bottomLeft'});
            }
        },
        emailBill(){
            this.$store.commit("setValidation",true); // enable validation
            if(this.validateSubmit()){
                const data = this.makeSubmitData();
                if (! data.appointment.id) {
                    this.addSchedule(data,false).then(() => {
                        this.sendEmail();
                    });
                } else {
                    this.updateSchedule(data,false).then(() => {
                        this.sendEmail();
                    });
                }
                
            }
        },
        worksheetBill(){
            this.$store.commit("setValidation",true); // enable validation
            if(this.validateSubmit()){
                const data = this.makeSubmitData();
                if (! data.appointment.id) {
                    this.addSchedule(data,false).then(() => {
                        window.open(API_BASE_URL + '/billings/'+ this.selectedBill.md5_id +'/print?document=worksheet&lang='+ (localStorage.getItem('languageId') || 'en') + "&user=" + localStorage.getItem('loginUserId'), "_blank");
                    });
                } else {
                    this.updateSchedule(data,false).then(() => {
                        window.open(API_BASE_URL + '/billings/'+ this.selectedBill.md5_id +'/print?document=worksheet&lang='+ (localStorage.getItem('languageId') || 'en') + "&user=" + localStorage.getItem('loginUserId'), "_blank");
                    });
                }
                
            }
        },
        sendEmail(){
            this.$store.state.isLoading = true;
            axios.get(API_BASE_URL + '/billings/'+ this.selectedBill.id +'/email', {headers: this.header})
            .then(response => {
                if (response.status === 200) {
                this.$noty.success( this.$t('billings.text_messages.email_sent_successfully'), { timeout: 5000, layout: 'bottomLeft'});
                }
                else {
                this.$noty.error( this.$t('billings.text_messages.failed_while_sending'), { timeout: 5000, layout: 'bottomLeft'});
                }
            })
            .catch((error) => {
                this.$noty.error( this.$t('billings.text_messages.failed_while_sending'), { timeout: 5000, layout: 'bottomLeft'});
            })
            .finally(() => {
                this.$store.state.isLoading = false;
            });
        },
        printReportFromTopButton(){
            this.$store.commit("setValidation",true); // enable validation
            if(this.validateSubmit()){
                const data = this.makeSubmitData();
                if (! data.bill.order_id) {
                    this.addSchedule(data,false).then(() => {
                    let depot_ids = new Set();
                    this.selectedArticles.forEach((article) => {
                        if(article.depot_in_id){
                            depot_ids.add(article.depot_in_id);
                        }
                        else if(article.depot_out_id){
                            depot_ids.add(article.depot_out_id);
                        }
                        
                    });
                    Array.from(depot_ids).forEach(element => {
                        if(element){
                            window.open(API_BASE_URL + '/depot/'+ this.encryptToMD5(element.toString()) +'/print?document=report&lang='+ (localStorage.getItem('languageId') || 'en')  + "&user=" + localStorage.getItem('loginUserId'), "_blank");
                        }
                    });
                    
                    });
                } else {
                    this.updateSchedule(data,false).then(() => {
                    let depot_ids = new Set();
                    this.selectedArticles.forEach((article) => {
                        if(article.depot_in_id){
                            depot_ids.add(article.depot_in_id);
                        }
                        else if(article.depot_out_id){
                            depot_ids.add(article.depot_out_id);
                        }
                    });
                    Array.from(depot_ids).forEach(element => {
                        if(element){
                            window.open(API_BASE_URL + '/depot/'+ this.encryptToMD5(element.toString()) +'/print?document=report&lang='+ (localStorage.getItem('languageId') || 'en' )  + "&user=" + localStorage.getItem('loginUserId'), "_blank");
                        }
                    });
                    
                    });
                }
                
            }
        },
        onResetPageData(){
            this.resetBillType();
            this.$store.commit("setValidation",false);
            this.appointmentdata = null;
            // this.$refs['clientComp'].$refs['clientName'].focus();
            this.order_status = null;
            this.payment_method = null;
            this.selectedBill = null;
            this.$store.commit("setBillCancelVisible",false);
            this.$store.commit("setEditingAllowed",false);
            this.selectedArticles = [];
            this.bill_amount = '';
            this.bills =[];
            this.rdv_date = null;
            this.due_date = new Date().toISOString().substr(0, 10);
            this.parent_reg_date = null;
            this.note = null;
            this.reference_client = null;
            this.unit_price = null;
            this.quantity = this.selectedFamilyArticle ? (this.selectedFamilyArticle.default_quantity) : 4;
            this.first_row_tariff = null;
            this.first_row_thtva = null;
            this.first_row_tva = this.cached_first_row_tva; // Reset TVA to company's Default TVA
            this.first_row_total = null;
            this.red_percentage = 0.00;
            this.unit_price = 0.00;
            this.next_event_code = null;
            this.finedSelectedArticles = [];
            this.articles = [];
            this.search_article = null;
            
            // Vehicle Component Sync Values
            this.parent_vehicle_type = null;
            this.parent_registration_no = null;
            this.parent_brand = null;
            this.parent_model = null;
            this.parent_km = null,
            this.parent_reg_date = null;
            this.parent_creditor = null;
            this.parent_vin = null;
            this.parent_is_leasing = null;

            // Client Component Sync Values
            this.parent_client_id = null;
            this.parent_company = null;
            this.parent_client_id = null;
            this.parent_company_client_name = null;
            this.parent_company_client_names = [];
            this.parent_search_client = null;
            this.parent_company_client_phone = null;
            this.parent_company_client_gsm = null;
            this.parent_company_client_fax = null;
            this.parent_company_client_email = null;
            this.parent_zipcode = null;
            this.parent_web = null;
            this.parent_currency = null;
            this.parent_representative_company_id = null;
            this.parent_user = null;
            this.parent_note = null;
            this.parent_familyClient = null;
            this.parent_denomination = null;
            this.parent_registration_code = null;
            this.parent_tva = null;
            this.parent_address = null;
            this.parent_city = null;
            this.parent_county = null;
            this.parent_country = null;
            this.parent_shipping_address = null;
            this.parent_shipping_city = null;
            this.parent_shipping_county = null;
            this.parent_shipping_country = null;
            this.parent_shipping_zipcode = null;
            this.parent_address_same_as = false;

            this.free_text = null;
            this.appointment_is_executed = false;
            this.storedDepotIn = [];

            this.bill_note_temp = null;
            this.bill_note = null;

            this.$store.commit("setShowNewButton",true);
        },
        convertCommaToDecimalSeparator(str){
            console.log(str,"comma seperated");
            return parseFloat((str+"").replace(',','.').replace(' ',''))
        },
        addDepotArticleToDataTable(depot, depotType){
            depot.details.forEach((depotDetail) => {
                let text = "";
                text += (depotType == 'out' ? this.$t('billings.text.depot_out') : this.$t('billings.text.depot_in')) + ": "
                        + depotDetail.article.title_description[0].description
                        + (depotDetail.position ? " - " + this.$t('billings.position.sh_'+ depotDetail.position.key): "")
                        + (depotDetail.rimtype ? " - " + this.$t('billings.wheel_materials.sh_'+ depotDetail.rimtype.key): "")
                        + (depotDetail.is_with_hubcaps == 1 ? " - " + this.$t('billings.text.with_hub_cups') : "")
                        + (depotDetail.mm ? " - " + this.$t('billings.text.mm') + ": " + depotDetail.mm : "")
                        + (depotDetail.couple ? " - " + this.$t('billings.text.couple') + ": " + depotDetail.couple : "")
                        + (depotDetail.pressure ? " - " + this.$t('billings.text.pressure') + ": " + depotDetail.pressure : "")
                        + (depotDetail.article_status ? " - " + this.$t('billings.text.status') + ": " + this.$t('billings.article_status.sh_'+ depotDetail.article_status.key) : "");
                
                if(depotType == 'out' && depotDetail.checked){
                    this.selectedArticles.push({
                        srno: this.rowNumber++,
                        article: 0,
                        article_original_name: null,
                        quantity: 1,
                        unit_price: 0.00,
                        rounded_unit_price: (0).toFixed(this.getDecimalNumber()),
                        red_percentage:  0.00,
                        tarrif: 0.00,
                        tva: 0.00,
                        thtva: 0.00,
                        stock_property: null,
                        total: 0.00,
                        text: text,
                        row:  0,
                        text_position: 0,
                        depot_out_id: depot.id,
                        depot_in_id: null,
                        dots: null
                    });
                }
                else if(depotType == 'in'){
                    this.selectedArticles.push({
                        srno: this.rowNumber++,
                        article: 0,
                        article_original_name: null,
                        quantity: 1,
                        unit_price: 0.00,
                        rounded_unit_price: (0).toFixed(this.getDecimalNumber()),
                        red_percentage:  0.00,
                        tarrif: 0.00,
                        tva: 0.00,
                        thtva: 0.00,
                        stock_property: null,
                        total: 0.00,
                        text: text,
                        row:  0,
                        text_position: 0,
                        depot_out_id: null,
                        depot_in_id: depot.id,
                        dots: null
                    });
                }
            });
        },
        addArticleToDataTable: function(e){
            if(e){
                e.preventDefault();
            }
            if(! (this.article && this.quantity && this.red_percentage <= 100) ){
                return false;
            }
            // resetting the article id in local storage
            var articleFilters = JSON.parse(localStorage.getItem("ARTICLE_MODEL_FILTERS") );
            if(articleFilters){
                articleFilters['id'] = null;
            }
            localStorage.setItem("ARTICLE_MODEL_FILTERS",JSON.stringify(articleFilters));


            console.log(this.unit_price, "dot sepeartor");
            this.unit_price = parseFloat(this.convertCommaToDecimalSeparator(this.unit_price) ?? 0);
            this.rounded_unit_price = parseFloat(this.convertCommaToDecimalSeparator(this.unit_price) ?? 0).toFixed(this.getDecimalNumber());
            this.red_percentage = parseFloat(this.red_percentage);

            // Add Related Articles
            this.getProposedArticles(this.article.id, this.article.family_id, this.parent_familyClient, parseFloat(this.quantity));

            // check if article already exists in the datatable
            let alreadyExistsArticle = this.selectedArticles.find((element,index) => {
                if( typeof element.article == "object" && element.article.id == this.article.id){
                    this.selectedArticles.splice(index, 1);
                    return element;
                }
                if( typeof element.article == "number" && element.article == this.article.id){
                    this.selectedArticles.splice(index, 1);
                    return element;
                }
            });
            if(alreadyExistsArticle){
                // recalculate the row total
                this.quantity = parseFloat(this.quantity) + parseFloat(alreadyExistsArticle.quantity);
                this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
                this.unit_price = parseFloat(this.unit_price);
                // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
                this.first_row_total = parseFloat(parseFloat(this.first_row_thtva) + parseFloat((this.first_row_tva * this.first_row_thtva ) / 100));
            }
            let isToMultiplyWithCredNoteMulValue = false;
            if(this.billType && this.billType.key == "credit_note"){
                isToMultiplyWithCredNoteMulValue = true;
            }
            
            this.selectedArticles.push({
                srno: this.rowNumber++,
                article: this.article,
                article_original_name: this.article,
                quantity: this.quantity,
                unit_price: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (this.unit_price ?? 0)) : (this.unit_price ?? 0)),
                rounded_unit_price: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (this.unit_price ?? 0)) : (this.unit_price ?? 0)).toFixed(this.getDecimalNumber()),
                red_percentage: parseFloat(this.red_percentage ?? 0.00),
                tarrif: this.first_row_tariff,
                tva: parseFloat(this.first_row_tva),
                thtva: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (this.first_row_thtva ?? 0)) : (this.first_row_thtva ?? 0)),
                stock_property: null,
                total: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (this.first_row_total ?? 0)) : (this.first_row_total ?? 0)),
                text: null,
                row: 0,
                text_position: 0,
                depot_out_id: null,
                depot_in_id: null,
                dots: null
            });
            this.resetFirstRowValues();
            this.resetSrNo();
        },
        resetSrNo(){
            let sno = 0;
            this.selectedArticles.forEach((element) => {
                element.srno = sno;
                sno++;
            })
            this.rowNumber = sno;
        },
        resetFirstRowValues(){
            this.article = '';
            this.quantity = this.selectedFamilyArticle ? (this.selectedFamilyArticle.default_quantity) : 4;
            this.red_percentage = 0.00;
            this.unit_price = 0.00;
            this.first_row_tariff = '';
            this.first_row_thtva = '';
            this.first_row_total = '';
        },
        deleteItem(item){
            this.selectedArticles.find((element,index) => {  
                if(element.srno == item.srno){
                    this.selectedArticles.splice(index,1);
                    this.resetSrNo();
                    return true;
                }
            });
        
        },
        onDelete () {
            if(this.appointmentdata.id){
                this.deletePopUp();
            }
        },
        makeClientData(){
            return {
                client_id                 : this.parent_company_client_name ? (typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.id : null) : null,
                entityType                : this.parent_entityType ? this.parent_entityType.id : 0, // By Default Private Client
                denomination              : this.parent_denomination          ?? null,
                familyClient              : this.parent_familyClient          ? (typeof this.parent_familyClient    == "object" ? parseInt(this.parent_familyClient.id) : this.parent_familyClient )  :  null,
                
                name                      : this.parent_company_client_name ? (typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.name : this.parent_company_client_name) : null,
                email                     : this.parent_company_client_email  ?? null,
                fax                       : this.parent_company_client_fax    ?? null,
                gsm                       : this.parent_company_client_gsm    ?? null,
                phone                     : this.parent_company_client_phone  ?? null,
                note                      : this.parent_note                  ?? null,
                tva                       : this.parent_tva                   ?? null,
                web                       : this.parent_web                   ?? null,
            };
        },
        makeVehicleData(){
            return {
                vehicle_id      : this.parent_registration_no && typeof this.parent_registration_no == "object" ? this.parent_registration_no.id : null,
                vehicle_type_id    : this.parent_vehicle_type && typeof this.parent_vehicle_type == "object" ? this.parent_vehicle_type.id : null,
                registration_no : this.parent_registration_no && typeof this.parent_registration_no == "object" ? this.parent_registration_no.registration_number : this.parent_registration_no,
                brand           : this.parent_brand ? (typeof this.parent_brand == "number" ? this.parent_brand : (typeof this.parent_brand == "object" ? this.parent_brand.id : null) )  : null,
                model           : this.parent_model ?? null,
                km              : this.parent_km ?? null,
                reg_date        : this.parent_reg_date ?? null,
                vin             : this.parent_vin ?? null,
                is_leasing      : this.parent_is_leasing ?? null,
                creditor_id     : this.parent_creditor ? (typeof this.parent_creditor == "object" ? this.parent_creditor.id : this.parent_creditor) : null,
            };
        },
        onConfirmationDeleteAppointment(){
            this.$store.state.isLoading = true;
            axios.delete(API_BASE_URL + '/appointments/' + this.appointmentdata.id,{headers: this.header})
            .then(({data}) => {
                this.onResetPageData()
                this.$noty.success( this.$t('scheduler.appointment.deleted_successfully'), { timeout: 5000, layout: 'bottomLeft'});
                this.$store.commit('setRefreshList', true);
                this.$router.push('/scheduler').catch(() => {});
            })
            .catch((error) => {
                this.$noty.error( this.$t('scheduler.appointment.delete_failed'), { timeout: 5000, layout: 'bottomLeft'});
            })
            .finally(() => {
                this.$store.state.isLoading = false; 
            });
        },
        makeSubmitData(){
            this.fineDataForSending();
            return {
                type           : this.billType ? this.billType.id : null,
                bill: ! this.selectedBill ? {} : {
                    order_id    : this.selectedBill.bill_number ? this.selectedBill.id : null,
                    client_id   : this.selectedBill ? this.selectedBill.client_id : null,
                    company_id  : this.selectedBill ? this.selectedBill.company_id : null,
                    vehicle_id  : this.selectedBill ? this.selectedBill.vehicle_id : null,
                },
                next_event_code : this.next_event_code ? this.next_event_code.id : null,
                status       : this.order_status ? this.order_status.id : null,
                payment_method : this.payment_method ? this.payment_method.id : null,
                rdv_date        : this.rdv_date,
                due_date        : this.due_date,
                reference_client: this.reference_client,
                note            : this.bill_note ?? null,
                client:{
                    client_id                 : typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.id : null,
                    entityType                : this.parent_entityType ? this.parent_entityType.id : 0, // By Default Private Client
                    denomination              : this.parent_denomination          ?? null,
                    familyClient              : this.parent_familyClient          ? (typeof this.parent_familyClient    == "object" ? parseInt(this.parent_familyClient.id) : this.parent_familyClient )  :  null,
                    
                    name                      : typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.name : this.parent_company_client_name,
                    email                     : this.parent_company_client_email  ?? null,
                    fax                       : this.parent_company_client_fax    ?? null,
                    gsm                       : this.parent_company_client_gsm    ?? null,
                    phone                     : this.parent_company_client_phone  ?? null,
                    note                      : this.parent_note                  ?? null,
                    tva                       : this.parent_tva                   ?? null,
                    web                       : this.parent_web                   ?? null,
                    notification_type_id      : this.notification_type ? this.notification_type.id : null,
                },
                billing_address:{
                    zipcode                   : this.parent_zipcode               ?? null,
                    city                      : this.parent_city    ? (typeof this.parent_city    == "object" ? parseInt(this.parent_city.id)    : this.parent_city) : null,
                    county                    : this.parent_county  ? (typeof this.parent_county    == "object" ? parseInt(this.parent_county.id)    : this.parent_county) : null,
                    country                   : this.parent_country ? (typeof this.parent_country == "object" ? this.parent_country.id : this.parent_country) : null,
                    address                   : this.parent_address ? (typeof this.parent_address == "object" ? parseInt(this.parent_address.id) : this.parent_address) : null,
                },
                shipping_address:{
                    zipcode                   : this.parent_shipping_zipcode               ?? null,
                    city                      : this.parent_shipping_city    ? (typeof this.parent_shipping_city    == "object" ? parseInt(this.parent_shipping_city.id)    : this.parent_shipping_city) : null,
                    county                    : this.parent_shipping_county    ? (typeof this.parent_shipping_county    == "object" ? parseInt(this.parent_shipping_county.id)    : this.parent_shipping_county) : null,
                    country                   : this.parent_shipping_country ? (typeof this.parent_shipping_country == "object" ? this.parent_shipping_country.id : this.parent_shipping_country) : null,
                    address                   : this.parent_shipping_address ? (typeof this.parent_shipping_address == "object" ? parseInt(this.parent_shipping_address.id) : this.parent_shipping_address) : null,
                },
                vehicle: this.makeVehicleData(),
                article           : this.finedSelectedArticles,
                appointment:{
                    id: this.appointmentdata ? this.appointmentdata.id : null,
                    start_time: this.makeStartTimeFromDateTime(),
                    end_time: this.makeEndTimeFromDateTime(),
                    work_type_id: this.work_type.id,
                    color: this.color ? this.color.replace('#','') : null,
                    work_resource_id: this.work_resource.id,
                    note: this.free_text ?? null,
                    appointment_is_executed: this.checkIfPastDateAppointment() ? null : (this.appointment_is_executed ? 1 : 0) // If past date appointment then dont send appointment_is_executed to the backend
                },
                depot_ins: this.storedDepotIn
            };
        },
        validateSubmit(){
            // let quantityValidation = false;
            // this.selectedArticles.forEach((element) => {
            //   if(element.article.stock < element.quantity){
            //       quantityValidation = true;
            //   }
            // })
            
            if (!this.billType || (!this.parent_company_client_name) ) {
                !this.billType ? this.$noty.error(this.$t('scheduler.text_messages.please_select_bill_type'), {  timeout: 5000, layout: 'bottomLeft'}) : '';
                !this.parent_company_client_name ? this.$noty.error(this.$t('scheduler.text_messages.please_select_client_name'), { timeout: 5000, layout: 'bottomLeft'}) : '';
                return false;
            }
            if( this.selectedBill && (this.selectedBill.bill_number ? this.selectedBill.id : null) && this.selectedArticles.length <= 0 && this.selectedBill.type.key == 'bill'){
                this.selectedArticles.length <= 0 ? this.$noty.error(this.$t('billings.text_messages.please_select_at_least_one_article'), { timeout: 5000, layout: 'bottomLeft'}) : '';
                return false;
            }
            // if (quantityValidation || this.$v.invalid ||  this.$refs['clientComp'].$v.invalid) {
            //   quantityValidation ? this.$noty.warning("Please verify the stock of the articles selected!", { timeout: 5000, layout: 'bottomLeft'})  : '';
            //   this.$v.invalid || this.$refs['clientComp'].$v.invalid ? this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft'})  : '';
                
            // }
            console.log( this.$refs['clientComp'].$v);
            if (this.$v.$invalid ||  ( this.checkTag('clients') ? this.$refs['clientComp'].$v.$invalid : false) ||  ( this.checkTag('vehicles') ? this.$refs['vehicleComp'].$v.$invalid : false)) {
                (this.$v.$invalid || ( this.checkTag('clients') ? this.$refs['clientComp'].$v.$invalid : false ) || ( this.checkTag('vehicles') ? this.$refs['vehicleComp'].$v.$invalid : false)) ? this.$noty.warning(this.$t('please_fill_form_correctly'), { timeout: 5000, layout: 'bottomLeft'})  : '';
                return false;
            }

            return true;
        },
        submit() {
            this.$store.commit("setValidation",true); // enable validation
            if(this.validateSubmit()){
                const data = this.makeSubmitData();
                console.log("validating submit", data);
                if (! data.appointment.id) {
                    this.addSchedule(data,true).then(() => {
                        this.onResetPageData()
                        this.$store.commit('setRefreshList', true);
                        this.$router.push('/scheduler').catch(() => {});
                    });
                } else {
                    this.updateSchedule(data,true).then(() => {
                        this.onResetPageData()
                        this.$store.commit('setRefreshList', true);
                        this.$router.push('/scheduler').catch(() => {});
                    });
                }
            }
        },
        getPaymentMethods () {
            axios.get(API_BASE_URL + '/codes?class_key=payment_types', {headers: this.header})
            .then(({ data }) => {
            this.payment_methods = this.translateKeys(data.data, 'key', 'billings.payment_methods');
            })
            .catch(function (error) {
            console.log('an error occured ' + error);
            });
        },
        getCreditors() {
            axios.get(API_BASE_URL + '/creditors', {headers: this.header})
            .then(({ data }) => {

            this.creditors = data.data.map((element) => {
                if(element.creditor_company){
                    element.creditor_company['is_leaser'] = element.is_leaser;
                    return element.creditor_company
                }
            });
            })
            .catch(function (error) {
            console.log('an error occured ' + error);
            });
        },
        getOrderStatuses () {
            axios.get(API_BASE_URL + '/codes?class_key=order_status', {headers: this.header})
            .then(({ data }) => {
                this.allStatuses = this.translateKeys(data.data, 'key', 'billings.statuses');
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            });
        },
        getNotificationTypes(){
            axios.get(API_BASE_URL + '/codes?class_key=client_notification_types', {headers: this.header})
            .then(({ data }) => {
                this.notification_types = this.translateKeys(data.data, 'key', 'scheduler.notification_types');
            })
            .catch(function (error) {
                console.log('an error occured ' + error);
            });
        },
        filterOutStatuses(){
            if(this.billType && this.billType.key == "estimation"){
                this.billstatuses = this.allStatuses.filter((element) => {
                if(this.statusFilters.estimation.find((el) => {return element.key == el})){
                    return element;
                }
                });
            }
            else if(this.billType && this.billType.key == "purchase_order"){
                this.billstatuses = this.allStatuses.filter((element) => {
                if(this.statusFilters.purchase_order.find((el) => {return element.key == el})){
                    return element;
                }
                });
            }
            else if(this.billType && this.billType.key == "bill"){
                this.billstatuses = this.allStatuses.filter((element) => {
                if(this.statusFilters.bill.find((el) => {return element.key == el})){
                    return element;
                }
                });
            }
            else if(this.billType && this.billType.key == "credit_note"){
                this.billstatuses = this.allStatuses.filter((element) => {
                if(this.statusFilters.credit_note.find((el) => {return element.key == el})){
                    return element;
                }
                });
            }
            else{
                return this.allStatuses;
            }
        },
        setCurrentFormOperation () {
            this.operation = (this.$route.path.indexOf('/add') > 0) ? 'add' : 'edit';
        },
        setBillType(){
            console.log("setting bill type", this.billTypes);
            this.billTypes.forEach((element,index) => {
                if(element.key == "purchase_order"){
                    // setting bill type with bill
                    this.billType = element;
                }
            });
        },
        addSchedule(data, showMessage= true){
            return new Promise((resolve, reject) => {
                this.$store.state.isLoading = true;
                axios.post(API_BASE_URL + '/appointments', data, {headers: this.header})
                .then(response => {
                    this.selectedBill = response.data.data;
                        if(showMessage){
                            this.$noty.success( this.$t('scheduler.text_messages.schedule_added') , { timeout: 5000, layout: 'bottomLeft'});
                        }
                        resolve();
                        // this.onResetPageData();
                })
                .catch((error) => {
                    if(error.response.data.errors[0]){
                        this.$noty.error(this.$t('scheduler.text_messages.' + error.response.data.errors[0]), { timeout: 5000, layout: 'bottomLeft'});
                    }
                    else{
                        this.$noty.error(this.$t('scheduler.text_messages.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
                    }
                    reject();
                })
                .finally(()=>{
                    this.$store.state.isLoading = false;
                });
            })
        },
        updateSchedule(data, showMessage = true, customData = null){
                // Add Notification Type Id to appointment object
                data.appointment.notification_type_id = this.notification_type ? this.notification_type.id : null;
                // let newdata = data.appointment;
                // newdata.client = data.client;
                // newdata.vehicle = data.vehicle;
                return new Promise((resolve, reject) => {
                    this.$store.state.isLoading = true;
                    axios.put(API_BASE_URL + '/appointments/' + data.appointment.id, (customData ? customData : data), {headers: this.header})
                    .then(response => {
                    this.selectedBill = response.data.data.order;
                        if (response.status === 200) {
                            if(showMessage){
                                this.$noty.success( this.$t('scheduler.text_messages.schedule_updated') , { timeout: 5000, layout: 'bottomLeft'});
                            }
                            resolve();
                            // this.onResetPageData();
                        }
                        else {
                            this.$noty.error(response, { timeout: 5000, layout: 'bottomLeft'});
                        }
                    })
                    .catch((error) => {
                        if(error.response.data.errors[0]){
                            this.$noty.error(this.$t('scheduler.text_messages.' + error.response.data.errors[0]), { timeout: 5000, layout: 'bottomLeft'});
                        }
                        else{
                            this.$noty.error(this.$t('scheduler.text_messages.something_went_wrong'), { timeout: 5000, layout: 'bottomLeft'});
                        }
                        reject();
                    })
                    .finally(()=>{
                        this.$store.state.isLoading = false;
                    });
                })
        },
        updatePagination (page) {
        // this.page = page;
        // this.fetchData();
        },
        updateItemsPerPage (itemsPerPage) {
        // console.log("----------",itemsPerPage == -1 ? this.totalItems : itemsPerPage);
        // this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
        // this.fetchData();
        // this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
        },
        clearArticleList() {
            this.articles = []
        },
        fetchArticleListDebounced() {
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchArticlesList()
            }, 500) /* 500ms throttle */
        },
        fetchArticlesList() {
            if(! this.search || typeof this.search == "object"){
                return ;
            }
            this.clearArticleList()
            axios.get(API_BASE_URL + '/billings/articles/search?page=1&items_per_page=10' + (this.parent_familyClient ? ('&family_client_for_pricing=' + this.parent_familyClient) : '') + (this.search ? ('&search=' + this.search) : '')  ,{headers: this.header})
            .then(({data}) => {
                if(data.data.length == 0){
                    this.$noty.error( this.$t('scheduler.appointment.no_pricing_found_for_this_article'), { timeout: 5000, layout: 'bottomLeft'});
                }
                this.articles = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingArticle = false;
            })
            .finally(() => (
                this.isLoadingArticle = false
            ))
        },
        fetchBillSuggesListDebounced() {
            clearTimeout(this._searchTimerId)
            this._searchTimerId = setTimeout(() => {
                this.fetchBillSuggesList()
            }, 500) /* 500ms throttle */
        },
        fetchBillSuggesList() {
            if(! this.search_bill || typeof this.search_bill == "object"){
                this.isLoadingSearchRegiClient = false;
                return ;
            }
            // this.clearArticleList()
            axios.get(API_BASE_URL + '/billings/search?page=1&items_per_page=20' + (this.search_bill ? ('&search=' + this.search_bill) : '') ,{headers: this.header})
            .then(({data}) => {
                this.bills = data.data;
            })
            .catch((err) => {
                console.log(err);
                this.isLoadingSearchRegiClient = false;
            })
            .finally(() => (
                this.isLoadingSearchRegiClient = false
            ))
        },
        calculateFields(UpdateQuantity, updateUnitPrice, updateTHTVA, updateTotal){
            // this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
            // this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
            // this.unit_price = "";
            if(UpdateQuantity){
                // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
            }
            if(updateUnitPrice){
                // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
            }
            if(updateTHTVA){
                this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
            }
            if(updateTotal){
                this.first_row_total = parseFloat(parseFloat(this.first_row_thtva) + parseFloat((this.first_row_tva * this.first_row_thtva ) / 100));
            }
        },
        calculateColumns(changeUnitPrice){
            if(this.article){
                this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
                console.log(this.unit_price,"dot sep");
                this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
                this.unit_price = parseFloat(changeUnitPrice ? this.unit_price : (
                    this.article.internal_company_article ? 
                    this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].price : 0
                    :
                    0.00
                ));
                this.rounded_unit_price = parseFloat(this.unit_price).toFixed(this.getDecimalNumber()),
                // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
                this.first_row_total = parseFloat(parseFloat(this.first_row_thtva) + parseFloat((this.first_row_tva * this.first_row_thtva ) / 100));
            }
        },
        calculateTotals(){
        let thtva_total = 0;
        let total_total = 0;
        this.selectedArticles.forEach(element => {
            thtva_total += parseFloat(element.thtva);
            total_total += parseFloat(element.total);
        });
        this.articleTHTVATotal =  parseFloat(thtva_total);
        this.articleTotal = parseFloat(total_total); 
        },
        fineDataForSending(){
            this.finedSelectedArticles = [];
            this.selectedArticles.forEach(element => {
                this.finedSelectedArticles.push({
                    article_id: element.text ? 0 : (typeof element.article == 'object' ? element.article.id : element.article), // If element has text property then we will send the article id as 0
                    // company_id: element.article.company_id,
                    // supplier_id: element.article.supplier_id,
                    quantity: element.quantity,
                    red_percentage: parseFloat(element.red_percentage ?? 0),
                    tarrif: parseFloat(element.tarrif),
                    thtva: parseFloat(element.thtva),
                    total: parseFloat(element.total),
                    tva: parseFloat(element.tva),
                    stock_property: element.stock_property ? ( typeof element.stock_property == 'object' ? element.stock_property.property : element.stock_property ) : null,
                    unit_price: parseFloat(element.unit_price),
                    rounded_unit_price: parseFloat(element.rounded_unit_price),
                    text: element.text,
                    row: element.row,
                    text_position: element.text_position ?? 0,
                    depot_out_id: element.depot_out_id,
                    depot_in_id: element.depot_in_id
                });
            });
        },
        fetchClientBalance(client_id){
            this.$store.state.isLoading = true;
            axios.get(API_BASE_URL + '/clients/' + client_id + '/pending/bill?return_total=1',{headers: this.header})
            .then(({data}) => {
                this.customer_balance = this.fixDecimal(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.$store.state.isLoading = false;
            })
        },
        setDefaultFamilyArticle(){
            let defaultFamilyArticle = null;
            this.familyArticles.forEach((element) => {
                if(element.is_default){
                defaultFamilyArticle = element;
                }
            })
            if(! defaultFamilyArticle){
                this.familyArticle = this.familyArticles[0];
            }
            else{
                this.familyArticle = defaultFamilyArticle;
            }
        },
        updateToTime(){
            let datetime = (new Date(this.datetime));
            let newdatetime = new Date(datetime.getTime() + ( this.work_type ? (this.work_type.work_duration ?? 0) : 0)*60000);
            let newtime = ("0" + newdatetime.getHours()).slice(-2) + ":" + ("0" + newdatetime.getMinutes()).slice(-2);
            this.time = newtime;
            
        },
        setStartTime(){
            let datetime = (new Date(this.datetime));
            let newdatetime = new Date(datetime.getTime());
            this.starttime = ("0" + newdatetime.getHours()).slice(-2) + ":" + ("0" + newdatetime.getMinutes()).slice(-2);
        },
        makeEndTimeFromDateTime(){
            let datetime = (new Date(this.datetime));
            let newdatetime = null;
            if(this.time){
                let startdate = datetime.getFullYear() + "-" + (datetime.getMonth() + 1)+"-"+datetime.getDate();
                newdatetime = new Date(startdate + " " + this.time);
            }
            else{
                newdatetime = new Date(datetime.getTime() + ( this.work_type ? (this.work_type.work_duration ?? 0) : 0)*60000);
            }
            console.log("new date time",newdatetime);
            return newdatetime.toISOString().substring(0,10) + " " +("0" + newdatetime.getHours()).slice(-2) + ":" + ("0" + newdatetime.getMinutes()).slice(-2);
        },
        makeStartTimeFromDateTime(){
            let datetime = (new Date(this.datetime));
            // let newdatetime = new Date(datetime.getTime() + ( this.work_type ? (this.work_type.work_duration ?? 0) : 0)*60000);
            console.log("New Date Time",this.datetime, datetime, datetime.toISOString().substring(0,10) + " " +("0" + datetime.getHours()).slice(-2) + ":" + ("0" + datetime.getMinutes()).slice(-2));
            return datetime.toISOString().substring(0,10) + " " +("0" + datetime.getHours()).slice(-2) + ":" + ("0" + datetime.getMinutes()).slice(-2);
        },
        getArticleDots(selectedArticleIndex, article_id, supplier_id){
            console.log("=SelectedArticle Index", selectedArticleIndex );
            console.log("=SelectedArticle article id", article_id );
            console.log("=SelectedArticle article id", article_id );
            console.log("=SelectedArticle supplier id", supplier_id );
            if(typeof article_id == 'object'){
            article_id = article_id.id;
            }
            axios.get(API_BASE_URL + '/stock_property/' + article_id + '?property_class_id=dot_property' + '&supplier_id=' + supplier_id, {headers: { Authorization: 'Bearer ' + this.$store.state.AccessToken }})
            .then((response) => {
                console.log(response.data.data,"selecte article dot items");
                this.selectedArticles[selectedArticleIndex].dots = response.data.data ?? [];
                if( ! this.selectedArticles[selectedArticleIndex].stock_property){
                    this.selectedArticles[selectedArticleIndex].stock_property = response.data.data ? response.data.data[0] : null;
                }
            })
            .catch((error) => {
                console.log('an error occured ' + error);
                this.selectedArticles[selectedArticleIndex].dots = [];
            })
            .finally(() => {

            });
        },
        setWorkType(){
            if(this.stored_work_types.length > 0 && this.work_resource ){
                this.work_types = this.stored_work_types.filter((element) => {
                    if(element.resource_work_types){
                        return element.resource_work_types.some((elementobject) => {return elementobject["work_resource_id"] === this.work_resource.id})
                    }
                    return false;
                })
                this.work_type = this.work_types[0];
            }
        }
    },
    computed: {
        vehicleDriverDetails(){
            let data = {
                client: this.makeClientData(),
                vehicle: this.makeVehicleData(),
                depot: this.parent_registration_no ? (typeof this.parent_registration_no == "object" ? this.parent_registration_no.depot : null) : null
            };
            
            return data;
        },
        companyNameLabel () {
            return this.isCompanyEntitySelected ? 'Company Name' : 'Full Name';
        },
        articles_list () {
            return this.articles;
        },
        // billType(){
        //     return this.$store.state.billType;
        // },
        isEditingAllowed(){
            return this.$store.state.isEditingAllowed;
        },
        swatchStyle() {
            const { color, menu } = this;
            return {
                backgroundColor: color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '4px' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        }
    },
    watch :{
        selectedFamilyArticle(){
            this.quantity = this.selectedFamilyArticle ? (this.selectedFamilyArticle.default_quantity) : 4;
            this.red_percentage = 0.00;
            this.unit_price = 0.00;
            this.first_row_tariff = '';
            this.first_row_thtva = '';
            this.first_row_total = '';
        },
        workresources(newval, oldval){
            console.log("work resource", newval, oldval);
            this.setWorkType();
        },
        storedworkresources(){
            console.log("stored wok resource", this.resourceType);
            if(this.resourceType){
                this.setWorkResources();
            }
        },
        note_dialog(val){
            if(val){
                this.bill_note_temp = this.bill_note;
            }
        },
        datetime(val){
            this.updateToTime();
            this.setStartTime();
            this.$store.commit('setSchedulerDate', val.substr(0, 10));
        },
        billTypes(){
            this.setBillType();
        },
        vehicleDriverDetails(val){
            console.log("depot in", val.client.name, val.client.client_id, val.vehicle.registration_no, !val.vehicle.vehicle_id, (val.client.name &&  !val.client.client_id) && (val.vehicle.registration_no && !val.vehicle.vehicle_id));
            if((val.client.client_id && val.vehicle.vehicle_id) ){
                console.log("vehicle depot", val.vehicle);
                if(typeof this.parent_registration_no == "object" && this.parent_registration_no.depot && this.parent_registration_no.depot.length > 0  ){
                    let depotOutAlreadyExists = false;
                    this.selectedArticles.forEach((article) => {
                        if(article.depot_out_id){
                            depotOutAlreadyExists = true;
                        }
                    });
                    if(depotOutAlreadyExists){
                        this.$store.commit("setShowDepotButtons",4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
                    }
                    else{
                        this.$store.commit("setShowDepotButtons",3); // Enable Both Depot In Depot Out
                    }
                }
                else{
                this.$store.commit("setShowDepotButtons",1); // Enable Just Depot In
                }
                
            }
            else if( (val.client.name || val.client.client_id) && (val.vehicle.registration_no && !val.vehicle.vehicle_id)){
                this.$store.commit("setShowDepotButtons",1); // Enable Just Depot In
            }
            else{
                this.$store.commit("setShowDepotButtons",0); // Dont show any button
            }
        },
        confirmationDialogButtonClickedIs(newVal){
            if(newVal == true &&  this.confirmationDialogOperation != null){
                if(this.confirmationDialogOperation == 'delete' && this.confirmationDialogButtonClickedIs == true){
                    this.onConfirmationDeleteAppointment();
                }
                if(this.confirmationDialogOperation == 'print_report' && this.confirmationDialogButtonClickedIs == true){
                    this.printReport();
                }
                if(this.confirmationDialogOperation == 'print_label' && this.confirmationDialogButtonClickedIs == true){
                    this.printLabel();
                }
                if(this.confirmationDialogOperation == 'make_bill' && this.confirmationDialogButtonClickedIs == true){
                    this.makeBill();
                }
                if(this.confirmationDialogOperation == 'make_purchase_order' && this.confirmationDialogButtonClickedIs == true){
                    this.makePurchaseOrder();
                }
                if(this.confirmationDialogOperation == 'make_credit_note' && this.confirmationDialogButtonClickedIs == true){
                    this.makeCreditNote();
                }
                
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        appointmentdata(val){
            if(val){
                this.$nextTick(() =>{
                    this.datetime = new Date(val.start_time).toISOString();
                    console.log("end time selected",val.end_time ,val.end_time.substr(11,5));
                    this.work_resource = val.work_resource;
                    this.work_type = val.work_type;
                    this.free_text = val.note;
                    this.appointment_is_executed =  val.appointment_status_id == 10481003; // If Executed then mark is work as checked 
                    this.color = "#" + val.color;
                    this.time = val.end_time.substr(11,5);
                    console.log("appointment data ", val.color);
                    if(!val.order){
                        let isCompany = !!val.client.company_id;
                        this.parent_entityType = isCompany ? this.entityTypeSelectItems[1] : this.entityTypeSelectItems[0];
                        this.parent_company_client_name = val.client;
                        this.parent_company_client_names = [val.client];
                        this.parent_familyClient = val.client.family_client_id;
                        
                        
                        this.parent_company_client_email = val.client.email;
                        this.parent_company_client_gsm = val.client.gsm;
                        this.parent_company_client_phone = val.client.phone;
                        this.parent_representative_company_id = val.client.representative_company_id;
                        
                        if(val.client.company){
                            this.parent_denomination = val.client.company.denomination_id;
                            this.parent_tva = val.client.company.tva
                        }
                    }
                });
                
            }
            else{
                // 
            }

                
                
        },
        work_type(val){
            if(val){
                this.updateToTime();
                this.color = "#"+val.color;
            }
        },
        parentSearchSelectedArticleId(val){
            console.log("parent search article done" ,val);
            if(val){
                this.dialog=false;
                this.$store.state.isLoading = true;

                axios.get(API_BASE_URL + '/billings/articles/search?page=1&items_per_page=10' + (this.parent_familyClient ? ('&family_client_for_pricing=' + this.parent_familyClient) : '') + ('&article_ids=' + val)  ,{headers: this.header})
                .then(({data}) => {
                    if(data.data.length == 0){
                        this.$noty.error( this.$t('scheduler.appointment.no_pricing_found_for_this_article'), { timeout: 5000, layout: 'bottomLeft'});
                    }
                    console.log(data.data[0]);
                    this.articles = [data.data[0]];
                    this.article = data.data[0]
                    this.isLoadingArticle = false;
                    this.$store.state.isLoading = true;
                    

                    var articleFilters = JSON.parse(localStorage.getItem("ARTICLE_MODEL_FILTERS") );
                    articleFilters['id'] = val;
                    localStorage.setItem("ARTICLE_MODEL_FILTERS",JSON.stringify(articleFilters));
                })
                .catch(() => {
                    this.$store.state.isLoading = false;
                })
                .finally(() => {
                    this.$refs['tableQuantity'].focus();
                    // this.parentSearchSelectedArticleId = null;
                    this.$store.state.isLoading = false;
                });
            }
            else{
                this.articles = [];
                this.article = '';
                this.unit_price = 0.00;
                this.red_percentage = 0.00;
            }
        },
        payment_method(val){this.checkEmptinessOfForm()},
        note(val){this.checkEmptinessOfForm()},
        reference_client(val){this.checkEmptinessOfForm()},
        first_row_tariff(val){this.checkEmptinessOfForm()},
        first_row_tva(val){this.calculateColumns(true); this.checkEmptinessOfForm()},
        first_row_thtva(val){this.checkEmptinessOfForm()},
        first_row_total(val){this.checkEmptinessOfForm()},
        next_event_code(val){this.checkEmptinessOfForm()},
        parent_search_client(val){this.checkEmptinessOfForm()},
        parent_company_client_phone(val){this.checkEmptinessOfForm()},
        parent_company_client_gsm(val){this.checkEmptinessOfForm()},
        parent_company_client_email(val){this.checkEmptinessOfForm()},
        parent_zipcode(val){this.checkEmptinessOfForm()},
        parent_note(val){this.checkEmptinessOfForm()},
        parent_denomination(val){this.checkEmptinessOfForm()},
        parent_tva(val){this.checkEmptinessOfForm()},
        parent_address(val){this.checkEmptinessOfForm()},
        parent_city(val){this.checkEmptinessOfForm()},
        parent_county(val){this.checkEmptinessOfForm()},
        parent_country(val){this.checkEmptinessOfForm()},
        parent_creditor(val){this.checkEmptinessOfForm()},
        parent_reg_date(val){this.checkEmptinessOfForm()},
        parent_model(val){this.checkEmptinessOfForm()},
        parent_km(val){this.checkEmptinessOfForm()},
        parent_brand(val){this.checkEmptinessOfForm()},
        parent_registration_no(val){this.checkEmptinessOfForm()},
        parent_vehicle_type(val){this.checkEmptinessOfForm()},
        parent_vin(val){this.checkEmptinessOfForm()},

        due_date(val){
            if(val && val.length > 10){
                this.due_date = val.substring(0,10); 
            }
        },

        parent_familyClient(val) {
            if(val){
                this.reCalculateSelectedArticlesPricing();
            }
            this.checkEmptinessOfForm();
        },
        parent_company_client_name(val){
            this.customer_balance = this.fixDecimal(0);
            this.checkEmptinessOfForm();
            if(this.selectedBill && this.selectedBill.vehicle){
                let billVehicle = val.vehicles.find((vehicle) => {
                    return vehicle.id == this.selectedBill.vehicle.id;
                });
                if(billVehicle){
                    val.vehicles.unshift(billVehicle);
                }
                this.parent_company_client_name = val;
            }
            else if(this.appointmentdata && this.appointmentdata.vehicle){
                console.log("finding vehicles", this.appointmentdata.vehicle, val);
                let billVehicle = val.vehicles.find((vehicle) => {
                    return vehicle.id == this.appointmentdata.vehicle.id;
                });
                if(billVehicle){
                    val.vehicles.unshift(billVehicle);
                }
                this.parent_company_client_name = val;
            }
            if(val && val.id){ 
                this.fetchClientBalance(val.id);
                console.log("notificationtype getig", val);
                if(val.notification_type_id){
                    this.notification_types.forEach((notification_type) => {
                        if(notification_type.id == val.notification_type_id){
                            this.notification_type = notification_type;
                        }
                    })
                }
                // this.notification_type = this.translateKeys([val.notification_type], 'key', 'scheduler.notification_types')[0];
                
            }
        },
        billType(val){
            this.checkEmptinessOfForm();
            // this.statuses = [];
            // this.order_status = null;
            this.filterOutStatuses();
            // if(val && val.key && val.key == 'purchase_order'){
            //     this.isVisibleOrderStatus = false;
            // }
            // else{
                this.isVisibleOrderStatus = true;
            // }
        },
        ispayment_method(){
            // this.payment_method = null;
        },
        order_status(val){
            this.checkEmptinessOfForm();
            this.ispayment_method = false;
            if(val && val.key == "payed"){
                this.ispayment_method = true;
            }
        },
        selectedArticles(){
            this.checkEmptinessOfForm();
            this.calculateTotals();
            this.fineDataForSending();
            
            // Enable or disable depot buttons based upon depot out
            let depotOutAlreadyExists = false;
            this.selectedArticles.forEach((article) => {
                if(article.depot_out_id){
                    depotOutAlreadyExists = true;
                }
            });
            if(this.$store.state.showDepotButtons == 3 || this.$store.state.showDepotButtons == 4 ){
                if(depotOutAlreadyExists){
                    this.$store.commit("setShowDepotButtons",4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
                }
                else{
                    this.$store.commit("setShowDepotButtons",3); // Enable Both Depot In Depot Out
                }
            }
            console.log("show depot buttons", this.$store.state.showDepotButtons);

            this.selectedArticles.forEach((article,index) => {
                console.log("dot article",article);
                // Fetch Dots only for tyre article
                if(article.article_original_name && article.article_original_name.family && (article.article_original_name.family.id == 1 || article.article_original_name.family.parent_family_id == 1) && (article.depot_out_id == null && article.depot_in_id == null)){
                    this.getArticleDots(index, article.article, 0);
                }
                else{
                    this.selectedArticles[index].dots = [];
                }
            });
        },
        article(val){
            this.checkEmptinessOfForm();
            this.calculateColumns();
        },
        quantity(val){
            this.checkEmptinessOfForm();
            this.calculateColumns(true);
        },
        unit_price(val){
            if(!val){
                val = 0;
            }
            
            this.checkEmptinessOfForm();
            this.unit_price = val ?? 0;
            if(this.pauseWatchForUnitPrice){
                this.pauseWatchForUnitPrice = false;
            }
            else{
                this.calculateColumns(true);
            }
            
        },
        red_percentage(val){
            this.checkEmptinessOfForm();
            this.calculateColumns(true);
        },
        search (val) {
            this.checkEmptinessOfForm();
            console.log(this.article);
            if (!val || (this.article && this.article.title_description[0].description == val) ) {
                return
            }
            this.isLoadingArticle = true;
            this.fetchArticleListDebounced()
        },
        search_bill (val) {
            this.checkEmptinessOfForm();
            if(val && typeof val == "object"){
                
                this.search_bill = val.bill_number ?  val.bill_number : (val.category == "vehicles" ? val.registration_number : val.name);
                this.allowContinueSearch = false;
                return;
            }
            
            if(val && this.allowContinueSearch){
                if (!val || (this.selectedBill && this.selectedBill.bill_number == val)) {
                    return
                }
                this.isLoadingSearchRegiClient = true;
                this.fetchBillSuggesListDebounced()
            }
            this.allowContinueSearch = true;
        },
        selectedBill(){
            this.checkEmptinessOfForm();
            if(this.selectedBill){
                this.$store.commit("setIsBillSelected",true);
                this.$store.commit("setSelectedBillData", this.selectedBill);
                if(this.selectedBill.appointment && this.selectedBill.appointment.id){
                    if(! this.appointmentdata){
                        this.fetchAppointmentByID(this.selectedBill.appointment.id, false);
                    }
                    this.$store.commit("setBillCancelVisible",true);
                    this.$store.commit("setEditingAllowed",true);
                    this.$store.commit('setFormMode', SCHEDULER_EDIT_MODE);
                }
            }
            else{
                this.$store.commit("setIsBillSelected",false);
                this.$store.commit("setSelectedBillData", null);
            }
            if(!this.selectedBill){
                this.$route.meta.title = "add_scheduler";
                this.onResetPageData();
            }
            if(this.selectedBill){
                this.bills = [this.selectedBill];
                this.$store.commit("setBillType",this.selectedBill.type);
                this.billType = this.selectedBill.type;
                this.selectedArticles = [];
                this.articles = [];
                this.article = '';
                
                if(this.selectedBill.bill_number){
                    this.$store.commit("setBillCancelVisible",true);
                    this.$store.commit("setEditingAllowed",true);
                }
                else{
                    this.$store.commit("setBillCancelVisible",false);
                    this.$store.commit("setEditingAllowed",false);
                }

                console.log("checking button status",this.selectedBill.bill_number, this.billType.key );
                if(this.selectedBill.bill_number &&  this.billType.key == "estimation"){
                    this.$store.commit("setMakeBillVisible",false);
                    this.$store.commit("setMakePurchaseOrderVisible",true);
                    this.$store.commit("setMakeCreditNoteVisible",false);
                }
                else if(this.selectedBill.bill_number &&  this.billType.key == "purchase_order" && this.selectedBill.status_id == 10251000    /* (10251000 = Open) */){
                    this.$store.commit("setMakeBillVisible",true);
                    this.$store.commit("setMakePurchaseOrderVisible",false);
                    this.$store.commit("setMakeCreditNoteVisible",false);
                }
                else if(this.selectedBill.bill_number &&  this.billType.key == "bill"){
                    this.$store.commit("setMakeBillVisible",false);
                    this.$store.commit("setMakePurchaseOrderVisible",false);
                    this.$store.commit("setMakeCreditNoteVisible",true);
                }
                let isCompany = !!this.selectedBill.client.company_id;
                this.parent_entityType = isCompany ? this.entityTypeSelectItems[1] : this.entityTypeSelectItems[0];
                this.parent_company_client_name = this.selectedBill.client;
                this.parent_company_client_names = [this.selectedBill.client];
                this.parent_familyClient = this.selectedBill.client.family_client_id;
                
                
                this.parent_company_client_email = this.selectedBill.client.email;
                this.parent_company_client_gsm = this.selectedBill.client.gsm;
                this.parent_company_client_phone = this.selectedBill.client.phone;
                this.parent_representative_company_id = this.selectedBill.client.representative_company_id;
                // this.parent_zipcode = this.selectedBill.client.zip;
                this.reference_client = this.selectedBill.reference;
                this.note = this.selectedBill.message;
                this.next_event_code = this.selectedBill.next_event_id;
                this.due_date = this.selectedBill.due_date;
                // if(this.selectedBill.billing_address){
                //   this.parent_zipcode = this.selectedBill.billing_address.zip;
                //   this.parent_address = this.selectedBill.billing_address.street;
                // }
                
                // if(this.selectedBill.client.client_address.length > 0){
                //   this.parent_zipcode = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.zip : null;
                //   this.parent_address = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.street : null;
                //   this.parent_city = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.city.name : null;
                //   this.parent_country = this.selectedBill.client.client_address[0].address ? this.selectedBill.client.client_address[0].address.city.country_id : null;
                // }

                if(this.selectedBill.billing_address_id && this.selectedBill.billing_address.id){
                this.parent_zipcode = this.selectedBill.billing_address ? this.selectedBill.billing_address.zip : null;
                this.parent_address = this.selectedBill.billing_address ? this.selectedBill.billing_address.street : null;
                this.parent_city = this.selectedBill.billing_address ? this.selectedBill.billing_address.city.name : null;
                this.parent_county = this.selectedBill.billing_address ? this.selectedBill.billing_address.county : null;
                this.parent_country = this.selectedBill.billing_address ? this.selectedBill.billing_address.city.country_id : null;
                }
                if(this.selectedBill.shipping_address_id && this.selectedBill.shipping_address.id){
                this.parent_shipping_zipcode = this.selectedBill.shipping_address ? this.selectedBill.shipping_address.zip : null;
                this.parent_shipping_address = this.selectedBill.shipping_address ? this.selectedBill.shipping_address.street : null;
                this.parent_shipping_city = this.selectedBill.shipping_address ? this.selectedBill.shipping_address.city.name : null;
                this.parent_shipping_county = this.selectedBill.shipping_address ? this.selectedBill.shipping_address.county : null;
                this.parent_shipping_country = this.selectedBill.shipping_address ? this.selectedBill.shipping_address.city.country_id : null;
                }
                
                if(this.selectedBill.client.company){
                this.parent_denomination = this.selectedBill.client.company.denomination_id;
                this.parent_tva = this.selectedBill.client.company.tva
                }
                if(this.selectedBill.details){
                    this.bill_note = this.selectedBill.message;
                    this.bill_note_temp = this.selectedBill.message;
                    this.rowNumber = 0;
                    this.selectedBill.details.forEach(element => {
                        let isToMultiplyWithCredNoteMulValue = false;
                        if(this.billType && this.billType.key == "credit_note"){
                            isToMultiplyWithCredNoteMulValue = true;
                        }
                        console.log("Is  to multiply", isToMultiplyWithCredNoteMulValue, element.price,isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (element.price ?? 0)) : (element.price ?? 0));
                        let thtva = (element.price * element.quantity) - ((element.price * element.quantity) * element.reduction )/100;
                        this.selectedArticles.push({
                            srno: this.rowNumber++,
                            article: element.article_id,
                            article_original_name: element.article,
                            quantity: element.quantity,
                            unit_price: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (element.price ?? 0)) : (element.price ?? 0)),
                            rounded_unit_price: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (element.rounded_unit_price ?? 0)) : (element.rounded_unit_price ?? 0)),
                            red_percentage: parseFloat(element.reduction ?? 0),
                            tarrif: parseFloat(element.article.tariff_price),
                            tva: parseFloat(element.tax),
                            thtva: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (thtva ?? 0)) : (thtva ?? 0)),
                            stock_property: element.stock_property ?? null,
                            total: parseFloat(isToMultiplyWithCredNoteMulValue ? ( parseInt(this.creditNoteMultiplicationValue.value) * (element.total ?? 0)) : (element.total ?? 0)),
                            text: element.text,
                            row: element.row,
                            text_position: element.text_position ?? 0,
                            depot_out_id: element.depot_out_id,
                            depot_in_id: element.depot_in_id,
                            dots: null
                        });
                    });
                }
                console.log("selected Bill ", this.selectedArticles);
                this.order_status = this.selectedBill.status;
                this.payment_method = this.selectedBill.payed_by_id ? this.selectedBill.payedBy : null ;
                
                this.showOrHidePrintReport();
            }
            else{
                this.$store.commit("setMakeBillVisible",false);
                this.$store.commit("setMakePurchaseOrderVisible",false);
                this.$store.commit("setMakeCreditNoteVisible",false);
            }
        }
    },
    }
</script>

<style scoped>
    .right-aligned-input >>> input {
        text-align: right
    }
    .customerbalance >>> .theme--light.v-label--is-disabled {
        color: rgb(255, 10, 10) !important;
    }
    .customerbalance >>> input {
        text-align: right;
        color: rgb(255, 0, 0) !important;
    }
    .customerbalance >>> .theme--light.v-icon.v-icon.v-icon--disabled {
        color: rgb(255, 0, 0) !important;
    }
    .articlenotset{
        display: flex !important;
    }
    .articleset{
        display: none !important;
    }
    .selectedArticleTextBox{
        background-color: rgb(220, 255, 220)
    }
    .dontbreakline{
        white-space: pre !important;
    }
</style>
